import styled from 'styled-components';

const DesktopFiltersContainer = styled.div`
  max-height: ${({theme}) => `calc(100vh - ${theme.layout.headerHeight})!important}`};
  display: flex;
  justify-content: center;
  border-left: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  width: 100%;
  padding-bottom: 15px;
  overflow-y: auto;
  
  ${({theme}) => theme.mq.big_desktop`
    overflow-y: hidden;

    ::-webkit-scrollbar {
      width: 5px;
      transition: .5s;
    }
    
    ::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }
         
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888; 
    }
        
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #888; 
    }
  `}
  
  &:hover {
    ${({theme}) => theme.mq.big_desktop`
      overflow-y: auto;
    `}
  }
`;

export default DesktopFiltersContainer;