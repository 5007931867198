import React from 'react';
import PropTypes from 'prop-types';
import PrivateRoute from './PrivateRoute';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

const BrandedRoute = ({givenComponent, brandRedirect, loginRedirect, availableFor, ...other}) => {
    const cookies = new Cookies();

    if (!cookies.get('selectedBrand')) {
        return (
            <Redirect to={brandRedirect} />
        );
    }

    return (
        <PrivateRoute
            component={givenComponent}
            redirect={loginRedirect}
            availableFor={availableFor}
            {...other}
        />
    );
};

BrandedRoute.defaultProps = {
    brandRedirect: '/brand-select',
    loginRedirect: '/login'
};

BrandedRoute.propTypes = {
    brandRedirect: PropTypes.string,
    loginRedirect: PropTypes.string,
    givenComponent: PropTypes.func.isRequired,
    availableFor: PropTypes.arrayOf(
        PropTypes.oneOf(['SV', 'FI', 'EM', 'WS'])
    ).isRequired,
};

export default BrandedRoute;