export default {
  BRAND_SELECT: '/brand-select',
  DASHBOARD: '/',
  LOGIN: '/login',
  PRODUCTS_LIST: '/products-list',
  PRODUCT: '/product',
  PRODUCTS_GRID: '/products-grid',
  FAVOURITE_PRODUCTS: '/favourite-products',
  CART: '/cart',
  PROFILE: '/profile',
  REPORT_BUG: '/report-bug',
  EXPORT_TEMPLATES: '/export-templates',
  FORGOT_PASSWORD: '/forgot-password',
  PASSWORD_RESET: '/password-reset',
  TERMS: '/terms-of-service',
  PROMOTIONS: '/promotions',
  NEW: '/new',
}
