import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {withApollo} from 'react-apollo';
import {withTranslation} from 'react-i18next';
import {Row, Col, Card, Divider, Dropdown, Icon, Menu, Button, Modal, Spin, message} from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import Cookies from 'universal-cookie';

import DELETE_EXPORT_TEMPLATE from "../../gql/mutations/DELETE_EXPORT_TEMPLATE";
import GET_EXPORT_TEMPLATES from "../../gql/queries/GET_EXPORT_TEMPLATES";

const StyledIcon = styled(Icon)`
  font-size: 1.6em;
`;

const StyledItem = styled(Menu.Item)`
  &:hover {
    background: ${({theme}) => theme.color.primaryColorLightest} !important;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 5px 0 !important;
`;


const StyledButton = styled(Button)`
  background: ${({theme}) => theme.color.primaryColor} !important;
  border-color: ${({theme}) => theme.color.primaryColor} !important;
  color: #fff !important;
  width: 7em;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12) !important;
`;

const StyledSpin = styled(Spin)`
  .ant-spin-dot-item {
    background: white;
  }
`;

message.config({maxCount: 1});

const cookies = new Cookies();

const DataExportCard = ({template, setModalVisible, setHandledTemplate, setModalAction, client, t}) => {
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const formattedDate = moment(template.createdAt).format('DD-MM-YYYY');

  const sendFileRequest = () => {
    setLoading(true);
    fetch(process.env.REACT_APP_URL_EXPORT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${cookies.get('jwttoken')}`,
      },
      body: JSON.stringify({
        export_type: 'normal',
        export_template_id: template.id,
        status: 'P',
      })
    }).then((response) => {
      setLoading(false);
      if (!response.ok) throw response;
      setConfirmVisible(false);
      return true;
    }).then(res => {
      message.success(t('confirmExportMessageContent'))
    }).catch(err => {
      message.error(t('base-error'));
    })
  };

  const confirmModal = () => (
    <Modal title={t('confirm-export', {templateName: template.name})}
           visible={confirmVisible}
           closable={false}
           destroyOnClose={true}
           footer={[
             <Button key='cancel' onClick={() => setConfirmVisible(false)} style={{width: '7em'}}>{t('cancel')}</Button>,
             loading ?
               <StyledButton key='disabled-export' disabled><StyledSpin size='small'/></StyledButton>
                 :
               <StyledButton key='export' onClick={() => sendFileRequest()}>{t('export')}</StyledButton>
           ]}
    >
      {t('confirm-export-content')}
    </Modal>
  );

  const deleteMutation = () => {
    client.mutate({
      mutation: DELETE_EXPORT_TEMPLATE,
      variables: {
        input: {
          id: template.id
        }
      },
      update: (store) => {
        const data = store.readQuery({
          query: GET_EXPORT_TEMPLATES,
          variables: {
            private: true
          }
        });
        let templates = data.me.exportTemplates.edges;
        templates = templates.filter(edge => edge.node.id !== template.id);
        data.me.exportTemplates.edges = templates;
        store.writeQuery({
          query: GET_EXPORT_TEMPLATES,
          variables: {
            private: true
          },
          data
        });
      }
    }).then(res => {
      if (res.data.deleteExportTemplateMutation.ok) message.success(t('delete-success'));
      else message.error(t('delete-fail'));
    })
  };

  const handleDelete = () => {
    Modal.confirm({
      title: t('confirm-delete', {templateName: template.name}),
      okText: t('delete'),
      okType: 'danger',
      cancelText: t('cancel'),
      onOk: () => deleteMutation(),
      width: '500px'
    });
  };

  const openModal = (action) => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width < 900) message.warning(t('screen-too-small'));
    else {
      setHandledTemplate(template);
      setModalAction(action);
      setModalVisible(true);
    }
  };

  const menu = () => (
    <Menu>
      <StyledItem key='0'
                  onClick={() => openModal('edit')}
      >
        {t('edit-template')}
      </StyledItem>
      <StyledItem key='1'
                  onClick={() => openModal('duplicate')}
      >
        {t('duplicate')}
      </StyledItem>
      <Menu.Divider />
      <StyledItem key='2'
                  onClick={() => handleDelete()}
      >
        {t('delete-template')}
      </StyledItem>
    </Menu>
  );

  return (
    <Card style={{margin: '10px 0'}} className='template-card'>
      <Row type='flex' justify='space-between' align='middle'>
        <Col span={template.private ? 22 : 23}>
          <Row type='flex' align='middle'
               onClick={() => setConfirmVisible(true)}
               style={{cursor: 'pointer'}}
          >
            <StyledIcon style={{marginRight: '5px'}} type='table' />
            <span>{template.name}</span>
          </Row>
          <StyledDivider />
          <h6>{t('card-text', {formattedDate, displayName: template.owner.displayName})}</h6>
        </Col>
        <Col span={1} style={{display: template.private ? 'block' : 'none'}}>
          <Dropdown trigger={['click']}
                    overlay={menu}
          >
            <StyledIcon type='more' />
          </Dropdown>
        </Col>
      </Row>
      {confirmModal()}
    </Card>
  )
};

DataExportCard.propTypes = {
  template: PropTypes.object.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  setHandledTemplate: PropTypes.func.isRequired,
  setModalAction: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const TranslatedComponent = withTranslation('dataExport')(DataExportCard);

export default withApollo(TranslatedComponent);