import styled from 'styled-components';

const StyledImgContainerPromo = styled.div`
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: fit-content;
    
    img {
        max-width: 100%;
    }
     
 
`;

export default StyledImgContainerPromo;