export default {
  'vaillant-offer': 'Vaillant offer',
  'saunier-offer': 'Saunier Duval offer',
  'new-products': 'New products',
  'discount-products': 'Discount products',
  'invalid-category': 'Such category doesn\t exist. Try in different brand.',
  'catalogue-number': 'Cat No.',
  'no-description': 'No description',
  'new' : 'New products,',
  'dateFrom': 'Offer valid from: '
}