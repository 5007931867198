import styled from 'styled-components';

const CategoryIconWrapper = styled.div`
  margin-bottom: 10px;
	img {
	  height: 70px;
	  max-width: 50%;
	}
`;

export default CategoryIconWrapper;