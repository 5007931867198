import styled from "styled-components";

const PromoDescription = styled.div`
    position: absolute;
    width: 360px;
    height: 360px;
    border-radius: 50%;
    overflow: hidden;
    z-index: 1;
    bottom: -30%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    
    span {
        color: #fff;
        font-weight: 600;
        text-transform: uppercase;
    }
    
    &.desc {
        border-radius: unset;
        left: 0;
        height: 50px;
        width: 100%;
        top: 0;
        background-color: ${({theme}) => `${theme.color.primaryColorRgba}`} ;
        display: flex;
        justify-content: center;
        align-items: center;
        
          ${({theme}) => theme.mq.desktop`
                width: 360px;
                height: 360px;
                right: -75px;
                bottom: -270px;
                border-radius: 50%;
                top: unset;
                left: unset;
          `}
        
        span {
            margin-top: 0;
            color: #fff;
            font-size: 14px;
            text-transform: uppercase;
            
            
            ${({theme}) => theme.mq.desktop`
                 position: absolute;
                    width: 60%;
                    left: 20%;
                    top: 25px;
                        
          `}
        }
    
    }
    
    &.promo-without-desc {
          background-color: ${({theme}) => `${theme.color.promoLabel}`} ;
         width: 144px;
         height: 144px;
         bottom: unset;
    }
    
    &.promo {
        background-color: ${({theme}) => `${theme.color.promoLabel}`} ;
        position: absolute;
        top: unset;
        bottom: -10px;
          width: 66px;
          height: 66px;
          
          &.product-list {
            right: 10px;
          }
          
        &.promoted {
            position: absolute;
            top: 80px;
            right: unset;
            
             ${({theme}) => theme.mq.tablet`
                  bottom: 15px;
                  left: unset;
                  right: 80px;
                  top: unset;
             `}
             
             ${({theme}) => theme.mq.big_desktop`
                  bottom: 15px;
                  left: 0;
                  right: unset;
                  top: unset;
             `}
             
             ${({theme}) => theme.mq.bigger_desktop`
                  bottom: 15px;
                  left: unset;
                  right: 95px;
                  top: unset;
             `}
             
             
        }
          &.product-view {
                left: calc(50% - 80px);
                bottom: -10px;
                
             ${({theme}) => theme.mq.tablet`
                position: absolute;
             `}
          }
            
         ${({theme}) => theme.mq.tablet`
            position: absolute;
            width: 80px;
            height: 80px;
            right: 90px;
            display: flex;
            justify-content: center;
            align-items: center;
         `}
    
         span {
               bottom: unset;
                font-size: 12px;
                text-align: center;
                text-transform: none;
                font-weight: 400;
         
         &.price-bold {
            font-size: 15px;
            font-weight: 700;
         
         }
         
            ${({theme}) => theme.mq.tablet`
              color: #fff;
            font-size: 12px;
            font-weight: 700;
        `}
           
      
        }
           
    }
    
`;

export default PromoDescription;