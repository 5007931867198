import gql from 'graphql-tag';

const EXPORT_TEMPLATE_MUTATION = gql`
  mutation exportTemplateMutation($input: ExportTemplateMutationInput!) {
    exportTemplateMutation(input: $input) {
      template {
        id
        name
        createdAt
        brand
        owner {
          displayName
        }
        private
        parameters {
          edges {
            node {
              id
            }
          }
        }
        categories {
          edges {
            node {
              id
            }
          }
        }
        attachmentTypes
      }
      ok
      errors {
        field
        messages
      }
    }
  }
`;

export default EXPORT_TEMPLATE_MUTATION;