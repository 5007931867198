import styled from "styled-components";


const StyledTile = styled.section`
  background-color: ${props => props.themeColor};
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  height: ${props => props.tileHeight + 'px'};
  transition: .3s;
/*
    max-width: 250px;
  max-height: 250px;
*/

  ${({theme}) => theme.mq.big_desktop`
    /*  max-width: 189px;
      max-height: 189px;*/
     
    `} 
  
  ${({theme}) => theme.mq.big_desktop`
/*      max-width: 185px;
      max-height: 185px;*/
        &:hover {
          background-color:  ${({theme}) => theme.color.tileColorHover};
        }
    `}
`;

export default StyledTile;
