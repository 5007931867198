import styled from 'styled-components';

const StyledPromotionItem = styled.div`
    padding: 23px 0;
    text-align: center;
      ${({theme}) => theme.mq.desktop`
        border-bottom: 1px solid #e8e8e8;
  `}
    
    h2 {
        color: ${({theme}) => theme.color.promoLabel};
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 5px;
        font-size: 16px;
    }
    
    p {
        font-size: 14px;
        margin-bottom: 15px;
    }
    
    img {
        max-width: 460px;
        height: auto;
    }
`;

export default StyledPromotionItem;