import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Link} from "react-router-dom";
import {CategoryIconWrapper, StyledTile} from '../_styledComponents';

const replacements = {
  ' ': '_',
  '-': '_',
  'ą': 'a',
  'ć': 'c',
  'ę': 'e',
  'ł': 'l',
  'ń': 'n',
  'ó': 'o',
  'ś': 's'
}
const getImagePath = name => `/category-icons/${name.replace(/( |-|ą|ć|ę|ł|ń|ó|ś)/g, match => replacements[ match ])}.svg`

const CategoryTile = ({id, name, logo, newProducts, stateHeight, height}) => {
  const tileSize = React.createRef();

  const StyledSpan = styled.span`
    color: ${({theme}) => theme.color.white};
    font-size: 14px;

    ${({theme}) => theme.mq.big_desktop`
        padding: 0 15px;
   `};
  `;

  useEffect(() => {
    const tileWidth = tileSize.current.clientWidth;
    if (stateHeight) stateHeight(tileWidth)
  }, []);

  const cretePath = newProducts ? `products-list/new/${id}` : `products-list/${id}`

  return (
    <div className="wrapper" ref={tileSize}>
      <Link to={cretePath}>
        <StyledTile tileHeight={height === null ? '170' : height} themeColor={({theme}) => theme.color.tileColor}>
          <CategoryIconWrapper>
            <img
              src={getImagePath(name || '')}
              alt={name}/>
          </CategoryIconWrapper>
          <StyledSpan>{name}</StyledSpan>
        </StyledTile>
      </Link>
    </div>
  );
};


/*CategoryTile.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};*/

export default CategoryTile;
