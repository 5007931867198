import React, {useContext} from "react";
import ThemeContext from "../../../context/themeContext/themeContext";
import {withTranslation} from "react-i18next";
import {Dashboard} from "../index";
import {withApollo} from 'react-apollo';


const NewProductsView = () => {
      const {brand} = useContext(ThemeContext);

    return (
        <Dashboard newProducts={true} brand={brand}/>
    )
}

const NewProducts = withApollo(NewProductsView)

export default withTranslation('promotions')(NewProducts);