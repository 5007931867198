import styled from 'styled-components';

const StyledPrice = styled.p`
	 text-align: right; 
   padding-right: 15px;
   margin: 10px 0 0;
   font-size: 1.17em;
   font-weight: 700;
   color: ${({theme}) => theme.color.primaryColor}; 
   
   
   &.product-view-price {
       ${({theme}) => theme.mq.big_desktop`
        font-size: 1.25rem;
        padding-right: 0;
    `};        
   }
   
   &.promoted-price {
     color: ${({theme}) => theme.color.primaryColor}; 
   }
   
  
`;

export default StyledPrice;