import styled from 'styled-components';

const StyledDiv = styled.div`
	 display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  
     
	 ${({theme}) => theme.mq.big_desktop`
      padding: 0;
  `};
`;

export default StyledDiv;