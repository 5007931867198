export default {
  'error': 'Error',
  'search-product-or-category': 'Search by product name, product number and category name',
  'categories': 'Categories',
  'products': 'Products',
  'price': 'Price',
  'load-more': 'Load more',
  'filter': 'Filter',
  'catalogue-number': 'Catalogue number',
  'clear-filters': 'Clear filters',
  'all-products': 'All products',
  'only-archive': 'Only archive',
  'only-published': 'Only published',
  'archive': 'Archive',
};