import gql from 'graphql-tag';

const GET_MAIN_CATEGORIES = gql`
	 query MainCategory($brand: String) {
    general {
      categories(isMain: true, brand: $brand, orderBy: "order") {
        edges {
          node {
            isMain
            id
            name
            displayName
            image
            childrenWithPublishedProducts {
              totalCount
            }
            childrenProductCount
            childrenWithProducts {
              totalCount
            }
            children {
              totalCount
            }
          }
        }
      }
    }
  }
`;

export default GET_MAIN_CATEGORIES;
