import React from 'react'
import {Collapse} from "antd";
import {Query} from "react-apollo";
import GET_ATTACHMENT_TYPES from "../../gql/queries/GET_ATTACHMENT_TYPES";
import FilesList from "../FilesList";
import styled from 'styled-components';

const StyledCollapse = styled(Collapse)`
   .ant-collapse-header {
        background-color:  #fff !important;
  }
`;

const Documents = ({id}) => {
  return (
    <Query query={GET_ATTACHMENT_TYPES}>
      {({data}) => {
        if (!data) return null;
        const documentsPanel = data.general.attachmentTypeValues.map((f, index) => (
          <FilesList key={index} header={f[1]} id={id} type={f[0]}/>
        ));
        return <StyledCollapse bordered={false}>
          {documentsPanel}
        </StyledCollapse>
      }}
    </Query>
  );
};

export default Documents;