import React, {useState, useEffect, useContext} from "react";
import {Icon} from "antd";
import {withApollo} from 'react-apollo';
import ProductsList from "../ProductsList";
import GET_CATEGORY_NAME from "../../gql/queries/GET_CATEGORY_NAME";
import {StyledTitle, StyledLink, StyledListContainer, DesktopFiltersContainer, StyledGrid} from "../_styledComponents";
import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import Filters from "../Filters";
import WindowWidthContext from "../../context/windowWidth/WindowWidthContext";
import ThemeContext from "../../context/themeContext/themeContext";

const ProductsListContainer = ({categoryId, client, t}) => {

  const [categoryName, setCategoryName] = useState('');
  const [invalidId, setInvalidId] = useState(false);
  const {brand} = useContext(ThemeContext)
  const {windowWidth} = useContext(WindowWidthContext);
  const cookies = new Cookies();
  const linkMessage = (cookies.get('selectedBrand') === 'V') ? t('vaillant-offer') : t('saunier-offer');
  useEffect(() => {
    const id = categoryId.category_id === 'new' ? categoryId.id : categoryId.category_id;

    if (id) {
      client.query({
        query: GET_CATEGORY_NAME,
        variables: {
          id: id,
          brand: cookies.get('selectedBrand'),
        }
      }).then(res => {
        if (res.data.general.categories.edges.length > 0) setCategoryName(res.data.general.categories.edges[0].node.name);
        else setInvalidId(true);
      });
    }
  }, [categoryId]);

  return (
    <StyledGrid>
      <StyledListContainer>
        <StyledTitle>
          <StyledLink to={categoryId.category_id === 'new' ? '/new' : '/'} style={{display: 'flex', justifyContent: 'space-between'}}>
           <div> <Icon type="left" style={{fontSize: '14px', marginRight: '5px', display: 'inline-block'}}/>{categoryId.category_id === 'new' ? t('new') : linkMessage}</div>
          </StyledLink>

        </StyledTitle>
        {invalidId ?
          <span style={{textAlign: 'center'}}>{t('invalid-category')}</span> :
          <ProductsList categoryId={categoryId}
                        name={categoryName}
          />
        }
      </StyledListContainer>
        {windowWidth === 'desktop' ?
          <DesktopFiltersContainer>
            {!invalidId && <Filters id={categoryId.category_id === 'new' ? categoryId.id : categoryId.category_id } setVisible={() => {}}/>}
          </DesktopFiltersContainer> : null
        }
    </StyledGrid>
  )
};

ProductsListContainer.propTypes = {
  categoryId: PropTypes.string.isRequired,
  client: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

const TranslatedComponent = withTranslation('productsListContainer')(ProductsListContainer);
export default withApollo(TranslatedComponent);