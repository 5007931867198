import gql from 'graphql-tag';

const GET_CATEGORIES = gql`
  query getCategories($searchName: String, $brand: String, $first: Int, $main: Boolean) {
    general {
      categories(name_Icontains: $searchName, brand: $brand, first: $first, isMain: $main) {
        totalCount
        edges {
          node {
            id
            name
            displayName
            parent {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default GET_CATEGORIES;
