import styled from 'styled-components';

const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: ${({theme}) => `calc(100vh - ${theme.layout.headerHeight}) !important}`};
  padding: 15px;
  
  ${({theme}) => theme.mq.big_desktop`
    padding: 30px 0 0;
    min-height: unset !important;
    height: fit-content;
    top: 64px;
    max-width: 310px;
    width: 100%;
    overflow-y: hidden;

    h3 {
      font-size: 14px;
    }
  `}
`;

export default FiltersContainer;