import gql from 'graphql-tag';

const PASSWORD_RESET_TOKEN_VALID = gql`
  query TokenValid($token: String!) {
    general {
      valid(token: $token)
    }
  }
`;

export default PASSWORD_RESET_TOKEN_VALID;