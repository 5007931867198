import styled from 'styled-components';
import {Card} from "antd";

const StyledBrandCard = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &.ant-card {
    margin: 20px
  }
`;

export default StyledBrandCard;