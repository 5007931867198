import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {StyledFilterTag} from "../_styledComponents";

const TagWrapper = ({id, choices, updateFilters, initialValues, isModel}) => {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (initialValues && initialValues.length) setSelected(initialValues);
    else setSelected([]);
  }, [initialValues]);

  const handleChange = (choice) => {
    let choiceValue = Array.from(selected);
    if (selected.indexOf(choice) !== -1) {
       choiceValue = choiceValue.filter(value => value !== choice);
       setSelected(choiceValue)
    } else  {
      choiceValue.push(choice);
      setSelected(choiceValue);
    }
    isModel ? updateFilters({models: choiceValue}) : updateFilters({paramId: id, values: choiceValue});
  };

  return (
    <div style={{overflowX: 'hidden'}}>
      {choices.map((choice, index) =>
        <StyledFilterTag onChange={() => handleChange(choice)}
                         key={index}
                         checked={selected && (selected.indexOf(choice) !== -1)}
        >
          {choice}
        </StyledFilterTag>
      )}
    </div>
  )
};

TagWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  choices: PropTypes.array.isRequired,
  updateFilters: PropTypes.func.isRequired,
  initialValues: PropTypes.array,
};

export default TagWrapper;