import gql from 'graphql-tag';

const DELETE_EXPORT_TEMPLATE = gql`
  mutation deleteExportTemplateMutation($input: DeleteExportTemplateMutationInput!) {
    deleteExportTemplateMutation(input: $input) {
      ok
      errors {
        field
        messages
      }
    }
  }
`;

export default DELETE_EXPORT_TEMPLATE;