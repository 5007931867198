import React, {useEffect, useState} from 'react';
import {Form, Input, message} from 'antd';
import {withTranslation} from "react-i18next";
import styled from 'styled-components';
import GENERAL_USER_DATA from "../../gql/queries/GENERAL_USER_DATA";
import UPDATE_USER from "../../gql/mutations/UPDATE_USER";
import client from "../../config/apollo/client";
import {StrongButton, StyledProfileForm} from "../_styledComponents";

const ProfileForm = ({t, form}) => {
  const {getFieldDecorator} = form;
  const [userId, setUserId] = useState('');
  const [username, setUsername] = useState('');
  const [userRole, setUserRole] = useState('');

  const hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  };

  const StyledSpan = styled.span`
    color: ${({theme}) => theme.color.primaryColorDark} !important;
  `;

  const handleSubmit = (e) => {
    e.preventDefault();
    client.mutate({
      mutation: UPDATE_USER,
      variables: {
        input: {
          id: userId,
          username: username,
          role: userRole,
          ...form.getFieldsValue()
        }
      }
    }).then(res => {
      message.config({maxCount: 1});
      if (res.data.updateUserMutation.errors) message.error(t('profile-save-error'));
      else message.success(t('profile-save-success'));
    })
  };

  useEffect(() => {
    client.query({
      query: GENERAL_USER_DATA
    }).then(res => {
      const userData = res.data.me.user;
      setUserId(userData.id);
      setUsername(userData.username);
      setUserRole(userData.role);
      form.setFieldsValue(userData);
    }).then(() => {
      form.validateFields();
    });
  }, []);

  const formItemLayout = {
    labelAlign: 'left',
    hideRequiredMark: true,
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: { span: 10 },
      sm: { span: 6 },
    },
  };

  return (
    <StyledProfileForm {...formItemLayout}
          onSubmit={(e) => handleSubmit(e)}
    >
      <Form.Item label={<StyledSpan>{t('name')}</StyledSpan>}>
        {getFieldDecorator('firstName', {
          rules: [{required: true, message: t('input-name')}]
        })(<Input />)}
      </Form.Item>
        <Form.Item label={<StyledSpan>{t('last-name')}</StyledSpan>}>
        {getFieldDecorator('lastName', {
          rules: [{required: true, message: t('input-lastname')}]
        })(<Input />)}
      </Form.Item>
        <Form.Item label={<StyledSpan>{t('email')}</StyledSpan>}>
        {getFieldDecorator('email', {})(<Input disabled={true}/>)}
      </Form.Item>
        <Form.Item label={<StyledSpan>{t('tax-id')}</StyledSpan>}>
        {getFieldDecorator('taxId', {})(<Input />)}
      </Form.Item>
        <Form.Item label={<StyledSpan>{t('company')}</StyledSpan>}>
        {getFieldDecorator('companyName', {})(<Input />)}
      </Form.Item>
        <Form.Item label={<StyledSpan>{t('phone-number')}</StyledSpan>}>
        {getFieldDecorator('phoneNumber', {})(<Input />)}
      </Form.Item>
      <Form.Item style={{display: 'flex', justifyContent: 'center'}} {...tailFormItemLayout}>
        <StrongButton
          className='center'
          style={{marginTop: '10px'}}
          themeColor={({theme}) => theme.color.primaryColor}
          htmlType='submit'
          disabled={hasErrors(form.getFieldsError())}
        >
            {t('save')}
        </StrongButton>
      </Form.Item>
    </StyledProfileForm>
  );
};

const WrappedForm = Form.create({name: 'profile'})(ProfileForm);
const TranslatedForm = withTranslation('profile')(WrappedForm);

export default TranslatedForm;