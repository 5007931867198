import gql from 'graphql-tag';

const GET_ARCHIVAL_PRODUCTS_CATEGORIES = gql`
    query($brand: String) {
      general {
        products(brand: $brand, status_In: "A") {
          totalCount
          edges {
            node {
              id
              mainCategory {
                logoPath
                name
                id
                  displayName
                 children {
                  totalCount
                }
              }
              name
              status
            }
          }
        }
      }
    }
`;

export default GET_ARCHIVAL_PRODUCTS_CATEGORIES;
