export default {
  'data-export': 'Data export',
  'your-templates': 'Your templates',
  'public-templates': 'Public templates',
  'edit-template': 'Edit template',
  'duplicate': 'Duplicate template',
  'delete-template': 'Remove template',
  'card-text': 'Added on {{formatted_date}} by {{displayName}}.',
  'confirm-export': 'Are you sure you want to export {{templateName}} template?',
  'confirm-export-content': 'You will receive an email with export file. It may take few minutes.',
  'confirmExportMessageContent': 'Please check your email in few minutes.',
  'export': 'Export',
  'save': 'Save',
  'cancel': 'Cancel',
  'confirm-delete': 'Are you sure you want to remove {{templateName}} template?',
  'delete': 'Remove',
  'base-error': 'Error',
  'save-and-export': 'Save and export',
  'duplicate-button': 'Duplicate',
  'duplicate-and-export': 'Duplicate and export',
  'create-template': 'Create new export template',
  'edit-export-template': 'Edit export template',
  'duplicate-export-template': 'Duplicate export template',
  'export-description-1': 'Every file contains product name and catalogue number.\n' +
    '                      If you select parameter, that is not available for\n' +
    'products in a given category, field will be marked as\n' +
    '"Not applicable".',
  'export-description-2': 'Remember that products parameters will appear in order of selection.',
  'template-name': 'File name',
  'brand': 'Brand',
  'template-categories': 'Product categories',
  'template-parameters': 'Export parameters',
  'include-attachments': 'Include attachments',
  'attachment-types': 'Attachment types',
  'delete-success': 'Template deleted successfully',
  'delete-fail': 'An error occurred while deleting',
  'screen-too-small': 'Your device is too small to use this component.',
  'export-template-success-message': 'Export template saved',
};