import React, {useState, useContext} from 'react';
import {Redirect} from 'react-router-dom';
import {withApollo} from 'react-apollo';
import {withTranslation} from "react-i18next";
import ThemeContext from "../../context/themeContext/themeContext";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Cookies from 'universal-cookie';
import {FlexWrapContainer, StyledBrandCard} from "../_styledComponents";
import ReactGA from 'react-ga';

const LogoImg = styled.img`
  height: ${({theme}) => theme.layout.logoHeight};
`;

const BrandSelector = ({t}) => {
    const cookies = new Cookies();
    const [redirect, setRedirect] = useState(cookies.get('selectedBrand'));
    const  {updateTheme}  = useContext(ThemeContext);
    if (redirect) {
        return <Redirect to='/' />
    }

    const handleBrandSelect = (brand) => {
        cookies.set('selectedBrand', brand, {path: '/'});
        ReactGA.set({'brand': brand});
        ReactGA.set({'dimension1': brand});
        updateTheme(brand);
        setRedirect(true);
    };

    return (
        <FlexWrapContainer>
            <span style={{fontSize: '18px', textTransform: 'uppercase'}}>{t('select-brand')}</span>
            <StyledBrandCard onClick={() => handleBrandSelect('V')}>
                <LogoImg src="/logo/vaillant-logo.png" alt="vaillant-logo" style={{height: '35px'}}/>
            </StyledBrandCard>
            <StyledBrandCard onClick={() => handleBrandSelect('S')}>
                <LogoImg src="/logo/saunier-logo.png" alt="saunier-duval-logo"/>
            </StyledBrandCard>
        </FlexWrapContainer>
    );
};


BrandSelector.propTypes = {
    t: PropTypes.func.isRequired,
};

const TranslatedComponent = withTranslation('brandSelect')(BrandSelector);

export default withApollo(TranslatedComponent);