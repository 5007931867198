export default {
  "log-in": "Zaloguj się",
  "mail": "Email",
  "password": "Hasło",
  "pass-err": "Proszę wprowadzić hasło!",
  "mail-err": "Proszę wprowadzić email!",
  "log-in-err": "Proszę podać właściwe dane logowania!",
  "forgot": "Zapomniałeś hasła?",
  "smth": "Coś poszło nie tak...",
  'advice': 'Do logowania używaj zawsze loginu i hasła jak do strony ' +
    'kodowanej dla profesjonalistów. Jeżeli zapomniałeś hasła odzyskaj ' +
    'je tak, jak dla strony kodowanej.'
}