import React from "react";
import StyledTileGridLayout from "../../../components/_styledComponents/StyledTileGridLayout";
import { useQuery } from "@apollo/react-hooks";
import GET_SUBCATEGORIES from "../../../gql/queries/GET_SUBCATEGORIES";
import ConstructTile from "../../../components/ConstructTile";
import CategoryBreadcrumb from "../../../components/CategoryBreadcrumb/CategoryBreadcrumb";
import CenteredSpin from "../../../components/_styledComponents/CenteredSpin/CenteredSpin";
import GET_CATEGORY_NAME from "../../../gql/queries/GET_CATEGORY_NAME";
import styled from "styled-components";

const StyledHeader = styled.div`
  margin: 2rem auto 0.5rem;
  max-width: ${({ theme }) => theme.layout.containerWidth};
  grid-column: 1/-1;
  position: relative;
  width: 100%;
  padding: 0 1rem;
`;

const StyledHeading = styled.h2`
  color: ${({ theme }) => theme.color.primaryColor};
  text-transform: uppercase;
  font-weight: 600;
  font-family: Arial;
  font-size: 1rem;
  margin-bottom: 0;
  padding-bottom: 1.25rem;
  text-align: left;
  ${({ theme }) => theme.mq.tablet`
    text-align: center;
    padding-bottom: 0;
  `}
`;

const ProductsGrid = ({ match }) => {
  const { category_id } = match.params;
  const { data: categoriesData, loading: categoriesLoading } = useQuery(
    GET_SUBCATEGORIES,
    { variables: { parent: category_id } }
  );
  const { data: categoryData, loading: categoryLoading } = useQuery(
    GET_CATEGORY_NAME,
    { variables: { id: category_id } }
  );
  const loading = categoryLoading || categoriesLoading;

  if (loading) return <CenteredSpin width="100vw" height="60vh" />;
  const { edges: categories } = categoriesData.general.categories;
  const category = categoryData.general.categories.edges[0].node;

  return (
    <StyledTileGridLayout>
      <StyledHeader>
        <CategoryBreadcrumb categories={categories} />
        <StyledHeading>{category.displayName || category.name}</StyledHeading>
      </StyledHeader>
      <ConstructTile categories={categories} />
    </StyledTileGridLayout>
  );
};

export default ProductsGrid;
