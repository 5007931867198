import styled from 'styled-components';
import {Button} from "antd";

const LightButton = styled(Button)`
  width: 120px;
  &.ant-btn {
    background-color: #fff;
    color: ${props => props.themeColor};
    border: 1px solid ${props => props.themeColor};
    
    .ant-btn-active {
       color: ${props => props.themeColor};
      border: 1px solid ${props => props.themeColor};
    }
    
    &:hover {
       color: ${props => props.themeColor};
      border: 1px solid ${props => props.themeColor};
    }
    
    &:active {
      color: ${props => props.themeColor};
      border: 1px solid ${props => props.themeColor};
    }
     &:focus {
      color: ${props => props.themeColor};
      border: 1px solid ${props => props.themeColor};
    }
  }
  
  &.center {
    display: block;
    margin: 0 auto;
  }
`;

export default LightButton;