export default {
  "categories": "Kategorie",
  "brand-select": "Wybierz markę",
  "products": "Produkty",
  "favourite-products": "Ulubione produkty",
  "export-templates": "Eksport danych",
  "cart": "Koszyk",
  "profile": "Mój profil",
  "report-bug": "Zgłoś błąd",
  "logout": "Wyloguj",
  'terms-of-service': 'Regulamin aplikacji',
  'archive': 'Archiwum',
  'no-products': 'Archiwum jest puste',
}