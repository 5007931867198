import styled from 'styled-components';


const ImageWrapper = styled.div`
	width: 130px; 
	height: 130px;
	min-width: 130px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
  
  img {
     display: block;
     width: auto; 
     height: auto; 
     max-width: 130px;
     max-height: 130px;
     margin: 0 auto;
  }	
  
  i.placeholder {
    display: block;
    margin: 0 auto;
    font-size: 50px;
    color: #ccc;
  }
`;

export default ImageWrapper;