import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Layout, Icon, Row, Tooltip, Button} from "antd";
import {Link, LogoWrapper, HeaderContainer} from '../../../../components/_styledComponents';
import Cookies from 'universal-cookie';
import {ReactComponent as SdLogo} from "../../../../images/sDLogo/SD_2D_horiz_4c.svg";
import Search from "../Search";
import DesktopHeaderRight from "../../../../components/DesktopHeaderRight/DesktopHeaderRight";
import ThemeContext from "../../../../context/themeContext/themeContext";
import {withTranslation} from "react-i18next";
import ArchiveContext from "../../../../context/archive/ArchiveContext";
import MenuContext from "../../../../context/menu/MenuContext";
import {useHistory} from "react-router-dom";


const {Header: AntHeader} = Layout;

const StyledIcon = styled(Icon)`
  font-size: 1.25rem;
  padding: 1rem;
`;

const LogoImg = styled.img`
  height: ${({theme}) => theme.layout.logoHeight};

`;

const ArchiveLabel = styled.div`
  text-transform: uppercase;
  background: ${props => props.brand === 'V' ? 'rgb(55,163,140)' : 'rgb(201,13,36)'};
  background: ${props => props.brand === 'V' ? 'linear-gradient(180deg, rgba(55,163,140,1) 0%, rgba(26,129,111,1) 50%, rgba(9,110,94,1) 100%)' : 'linear-gradient(180deg, rgba(201,13,36,1) 0%, rgba(175,5,19,1) 50%, rgba(160,0,9,1) 100%)'};
  height: auto;
  font-size: 12px;
  bottom: -10px;
  position: absolute;
  font-weight: 600;
  width: 100%;
  height: 15px;
  text-align: center;
  color: #fff;
  line-height: 15px;
  ${({theme}) => theme.mq.big_desktop`
    right: 0;
    padding: 0 10px;
    font-size: 12px;
    height: unset;
    bottom: unset;
    width: auto;
    height: auto;
    line-height: 64px;
 `}
`;


const FixedButton = styled(Button)`
  position: fixed !important;
  top: 10px;
  left: 20px;
  border: 3px solid ${({theme}) => theme.color.primaryColor} !important;
  height: 45px !important;
  width: 45px !important;
  border-radius: 50% !important;
  color: ${({theme}) => theme.color.primaryColor} !important;
  font-size: 26px !important;
  padding: 0 !important;
  z-index: 1000;
  @media (max-width: 1500px) {
    position: static !important;
  }
`;

const Header = ({menu, search, toggleMenu, toggleSearch, windowWidth, handleBrandChange, t}) => {
  const cookies = new Cookies();
  const cookieBrand = cookies.get('selectedBrand');
  const {brand} = useContext(ThemeContext);
  const [ logo, setLogo ] = useState(null);
  const {openArchive} = useContext(ArchiveContext);
  const {setOpenKeys, setOpenKeysLevel} = useContext(MenuContext);
  const history = useHistory();


  useEffect(() => {
    if (brand === 'V') {
      setLogo(<LogoImg src='/logo/vaillant-logo.png' alt='vaillant-logo'/>)
    } else if (brand === 'S') {
      setLogo(<LogoWrapper><SdLogo/></LogoWrapper>)
    }
    setOpenKeys([]);
    setOpenKeysLevel([])
  }, [ brand ]);

  const logout = () => {
    cookies.remove('selectedBrand', {path: '/'});
    cookies.remove('jwttoken', {path: '/'});
    cookies.remove('role', {path: '/'});
  };

  const StyledHeader = styled(AntHeader)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: ${({theme}) => theme.zIndex.navigation};
    width: 100%;

    &.brand-is-selected {
      -webkit-box-shadow: 0 3px 5px -6px black;
      -moz-box-shadow: 0 3px 5px -6px black;
      box-shadow: 0 3px 5px -6px black;
    }

    ${({theme}) => theme.mq.big_desktop`
            justify-content: space-between;
            align-items: unset;
            
            &.ant-layout-header {
                padding: .25rem 1rem !important;
            }
        `}
  `;

  if (!cookieBrand) {
    return (
      <StyledHeader>
        <Row type='flex' justify='end' style={{width: '100%'}}>
          <Link onClick={logout} to='/'><StyledIcon type={'logout'}/></Link>
        </Row>
      </StyledHeader>
    )
  }

  return (
    <StyledHeader className='brand-is-selected'>
      {windowWidth === 'mobile' ?
        <>
          <StyledIcon type={menu ? 'close' : 'menu'} onClick={toggleMenu}/>
          {logo}
          <StyledIcon type={search ? 'close' : 'search'} onClick={toggleSearch}/>
          {openArchive && <ArchiveLabel brand={brand}><span>{t('archive')}</span></ArchiveLabel>}

        </> : <HeaderContainer>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            position: "relative",
          }}>
            {history.location.pathname !== '/' && history.location.pathname !== '/brand-select' ? <FixedButton
              onClick={history.goBack}
              themeColor={({theme}) => theme[ brand ].primaryColor}>
              <Icon type={'arrow-left'}/>
            </FixedButton> : null
            }
            <StyledIcon type={menu ? 'close' : 'menu'} onClick={toggleMenu}/>
            {logo}
            {openArchive && <ArchiveLabel brand={brand}><span>{t('archive')}</span></ArchiveLabel>}
          </div>

          <div style={{display: 'flex', width: '100%', justifyContent: "center"}}>
            <Tooltip placement="right" title={t('search-product-or-category')}>
              <div style={{display: 'flex'}}>
                <Search drawerVisible={() => {
                }}/>
              </div>
            </Tooltip>
          </div>
          <DesktopHeaderRight handleBrandChange={handleBrandChange}/>
        </HeaderContainer>
      }
    </StyledHeader>
  );
};

export default withTranslation('search')(Header);
