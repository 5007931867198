import React from 'react';

import {Layout} from 'antd';
import styled from 'styled-components';

const { Footer: AntFooter } = Layout;

const StyledFooter = styled(AntFooter)`
  width: 100%;
  background: ${({theme}) => theme.color.white} !important;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &.ant-layout-footer {
    padding: 15px;
    margin-bottom: 0;
  }
`;

const Footer = () => (
  <StyledFooter>
    <p style={{marginBottom: 0}}>Vaillant Saunier Duval Sp. z o.o.</p>
  </StyledFooter>
);

Footer.defaultProps = {};

Footer.propTypes = {};

export default Footer;