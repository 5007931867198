import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {
  Form, Input, Button,
} from 'antd';
import {withApollo} from "react-apollo";
import {withTranslation} from "react-i18next";
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import GET_TOKEN from '../../gql/mutations/GET_TOKEN';
import {ErrorMsg, Icon} from '../_styledComponents';
import GENERAL_USER_DATA from "../../gql/queries/GENERAL_USER_DATA";
import styled from 'styled-components';

const Advice = styled.div`
  width: 100%;
  font-size: 0.8em;
  text-align: justify;
  line-height: 1em;
`;

const hasErrors = fieldsError => {
  return Object.keys(fieldsError).some(field => fieldsError[ field ]);
};

const LoginForm = ({form, client, redirect, t}) => {

  const {getFieldDecorator, getFieldsError, getFieldError, isFieldTouched, validateFields, setFieldsValue} = form;
  const cookies = new Cookies();

  const [ generalError, setGeneralErrorMsg ] = useState('');

  useEffect(() => {
    validateFields();
  }, []);

  const handleError = () => {
    setGeneralErrorMsg(t('log-in-err'));
  };

  const resetError = () => {
    setGeneralErrorMsg('')
  };

  const handleSubmit = e => {
    e.preventDefault();
    validateFields((err, value) => {
      if (!err) {
        client.mutate({
          mutation: GET_TOKEN,
          variables: {
            email: value.email,
            password: value.password
          }
        }).then(({data}) => {
            cookies.set('jwttoken', data.tokenAuth.token, {path: '/'});
            client.query({
              query: GENERAL_USER_DATA,
              fetchPolicy: 'network-only',
            }).then(({data}) => {
              cookies.set('role', data.me.user.role, {path: '/'});
              redirect(true);
            });
          })
          .catch(err => handleError());
      }
    });
  };

  const handleChange = e => {
    setFieldsValue({'email': e.target.value});
    resetError();
  };

  const userNameError = isFieldTouched('email') && getFieldError('email');
  const passwordError = isFieldTouched('password') && getFieldError('password');

  return (
    <Form onSubmit={handleSubmit} style={{height: '150px'}}>
      <Form.Item
        validateStatus={userNameError ? 'error' : ''}
        help={userNameError || ''}
      >
        {getFieldDecorator('email', {
          trigger: 'onBlur',
          rules: [{required: true, type: 'email', message: t('mail-err')}],
        })(
          <Input
            onChange={handleChange}
            prefix={<Icon type="user"/>} placeholder={t('mail')}/>
        )}
      </Form.Item>
      <Form.Item
        validateStatus={passwordError ? 'error' : ''}
        help={passwordError || ''}
      >
        {getFieldDecorator('password', {
          rules: [ {required: true, message: t('pass-err')} ],
        })(
          <Input.Password
            onChange={resetError}
            prefix={<Icon type="lock"/>} placeholder={t('password')}/>
        )}
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={hasErrors(getFieldsError()) || generalError}
          style={{width: '100%'}}
        >
          {t('log-in')}
        </Button>
      </Form.Item>
{/*      <Link to="/forgot-password">
            {t('forgot')}
      </Link>*/}
          {t('forgot')}
        {generalError === '' ?
             null
            :   <ErrorMsg
                    minHeight={25}>
                    {generalError}
                  </ErrorMsg>}
      <Advice style={{marginTop: '10px'}}>{t('advice')}</Advice>
    </Form>
  )
};

LoginForm.propTypes = {
  form: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const AntLoginForm = Form.create({name: 'horizontal_login'})(LoginForm);
const TranslatedComponent = withTranslation('auth')(AntLoginForm);

export default withApollo(TranslatedComponent);