import styled from "styled-components";

const StyledPromoPrice = styled.p`
    text-align: center; 
    position: relative;
    margin: 10px 0 0;
    margin-right: 15px; 
    font-size: .8em;
    font-weight: 700;
    color: ${({theme}) => theme.brandlessColors.darkGrey};
   
   &.mobile {
    text-align: right;
    display: inline;
    
    .promo-line {
        left: 0;
      
    }
   }
   
   .promo-line {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: calc(45% - 1px);
        height: 1px;
        background-color: ${({theme}) => theme.brandlessColors.darkGrey};
`;

export default  StyledPromoPrice;
