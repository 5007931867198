import {
    StyledGrid,
    StyledListContainer
} from "../../../components/_styledComponents";
import React, {useContext} from "react";
import PromotionsList from "../../../components/PromotionsList/PromotionsList";
import ThemeContext from "../../../context/themeContext/themeContext";
import PromotedProducts from "../../../components/PromotedProducts";
import {withTranslation} from "react-i18next";


const Promotions = ({t}) => {
      const {brand} = useContext(ThemeContext);
    return (
        <StyledGrid isPromoView>
            <StyledListContainer style={{ borderRight: '1px solid #e8e8e8'}}>
                <PromotionsList t={t}/>
            </StyledListContainer>
            <PromotedProducts brand={brand} t={t}/>
        </StyledGrid>
    )
}

export default withTranslation('promotions')(Promotions);