import styled from "styled-components";
import { Drawer } from "antd";

const StyledDrawer = styled(Drawer)`
  margin-top: ${({ theme }) => theme.layout.headerHeight};
  position: fixed;
  top: 0;
  & .ant-drawer-content {
    height: ${({ theme }) =>
      `calc(100vh - ${theme.layout.headerHeight})`} !important;
  }
  .ant-drawer-content-wrapper {
    transform: ${({ isOpen, placement }) => {
      if (placement === "left" && !isOpen) {
        return "translateX(-200%) !important";
      }
      return "translateX(0)";
    }};
  }
`;

export default StyledDrawer;
