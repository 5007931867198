import gql from 'graphql-tag';

const GET_CATEGORY_PARAMETERS = gql`
  query CategoryParameters($categoryIds: [ID!], $internalParams: Boolean) {
    general {
      parameters(category: $categoryIds, internal: $internalParams, orderBy: "name") {
        edges {
          node {
            id
            name
            category {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default GET_CATEGORY_PARAMETERS;