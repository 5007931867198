import styled from 'styled-components';

const StyledListItem = styled.li`
  position: relative;
	list-style-type: none; 
	border-bottom: 1px solid ${({theme}) => theme.brandlessColors.lightGrey};
	padding: 25px 0;
	margin: 0 5px;
	
	h3 {
	  line-height: 1;
	}
	
	 ${({theme}) => theme.mq.big_desktop`
        padding: 25px 0;
        margin: 0 10px;
    `}
`;

export default StyledListItem;