import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';
import routes from "../../../config/routes";
import LayoutCenter from "../../../containers/LayoutCenter";
import FormContainer from "../../../containers/FormContainer";
import ForgotPasswordForm from '../../../components/ForgotPasswordForm';

const ForgotPassword = () => {
  const [redirect, setRedirect] = useState(false);

  if (redirect) return <Redirect to={routes.LOGIN}/>;

  return (
    <LayoutCenter>
      <FormContainer>
        <ForgotPasswordForm redirect={setRedirect}/>
      </FormContainer>
    </LayoutCenter>
  )
};

ForgotPassword.defaultProps = {};
ForgotPassword.propTypes = {};
export default ForgotPassword;