const CATEGORY_TYPES = {
  PRIMARY: "main-categories",
  SECONDARY: "subcategories",
};

/**
 * @param {number} numberOfProducts The amount of products in children.
 * @returns {boolean}
 */
const checkPublishedCategories = (numberOfProducts) => numberOfProducts !== 0;

/**
 * @param {number} numberOfProducts The amount of products in children.
 * @param {number} currentCategory Number of products in the current category.
 * @returns {boolean}
 */
const checkPublishedSubCategories = (numberOfProducts, currentCategory) =>
  numberOfProducts !== 0 || currentCategory !== 0;

/**
 * Utility function for removing unpublished categories.
 * @param {Array} categories
 * @param {string} categoryType
 * @returns {Array} List of published products.
 */
export const removeCategoriesWithoutProducts = (categories, categoryType) => {
  if (categories) {
    return categories.filter((category) => {
      if (categoryType === CATEGORY_TYPES.PRIMARY) {
        return checkPublishedCategories(
          category.node.childrenWithPublishedProducts &&
          category.node.childrenWithPublishedProducts.totalCount
        );
      }
      if (categoryType === CATEGORY_TYPES.SECONDARY) {
        return checkPublishedSubCategories(
          category.node.childrenWithPublishedProducts &&
          category.node.childrenWithPublishedProducts.totalCount,
          category.node.products.totalCount
        );
      }
      return category;
    });
  }
  return [];
};
