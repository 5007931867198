import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {withApollo} from 'react-apollo';
import styled from 'styled-components';
import {Carousel, Col, Row, Card, Divider, Icon} from 'antd';
import GET_PRODUCT_KIT from "../../gql/queries/GET_PRODUCT_KIT";

const StyledDivider = styled(Divider)`
  margin: 5px 0 !important;
`;

const StyledCard = styled(Card)`
  border-color:  ${({theme}) => theme.brandlessColors.lightGrey} !important;
  border-radius: 4px !important;
  width: 200px;
  height: 280px;
  
  .ant-card-body {
    padding: 5px 15px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const NameSpan = styled.span`
  font-weight: bold;
  text-decoration: underline;
  font-size: 0.9em;
  height: auto;
  text-align: center;
  margin-bottom: 5px;
`;

const ContentSpan = styled.span`
  font-size: 0.8em;
`;

const PhotoContainer = styled.div`
  height: 100px;
  margin-bottom: 10px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  
   img {
     width: auto; 
     height: auto;
     max-width: 100px;
     max-height: 100px;
     margin: 0 auto;
   }
`;

const StyledCarousel = styled(Carousel)`
  width: 100vw;
  overflow: hidden;

  .slick-track {
    width: max-content !important;
  }
`;

const StyledDesktopCarousel = styled(Carousel)`
  width: 630px;
  overflow: hidden;
  margin-top: 15px;
`;

const StyledIcon = styled(Icon)`
  font-size: 1.8em;
  margin: 0 15px;
`;

const EquipmentCards = ({id, equipmentType, client, t}) => {
  const [windowWidth, setWindowWidth] = useState(0);
  const [equipment, setEquipment] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('resize', () => setWindowWidth(
      Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
    ));
    setWindowWidth(window.innerWidth);

    client.query({
      query: GET_PRODUCT_KIT,
      variables: {
        id: id,
        type: equipmentType,
        status: 'P',
      }
    }).then(res => {
      const relations = res.data.general.products.edges[0].node.toProduct.edges;
      const equipmentItems = relations.map(item => item.node.fromProduct);
      setEquipment(equipmentItems);
    })
  }, [id]);

  const EquipmentCard = ({product}) => (
    <Link to={`/product/${product.category.id}/${product.id}`}>
      <StyledCard>
          <NameSpan>{product.name}</NameSpan>
          <PhotoContainer>
            {product.attachments.edges.length === 0 ?
              <Icon type="picture" style={{ fontSize: '50px', color: '#ccc'}} />
               :
              <img src={`${process.env.REACT_APP_URL}${product.attachments.edges[0].node.file}`} alt={''}/>
            }
          </PhotoContainer>
          <ContentSpan>{product.currentPrice ? `${product.currentPrice.value} PLN/szt.` : t('no-price')}</ContentSpan>
        <StyledDivider/>
          <ContentSpan>{`${t('product-code')}:`}</ContentSpan>
          <ContentSpan>{`${product.catalogueNumber}`}</ContentSpan>
      </StyledCard>
    </Link>
  );

  const DesktopCarousel = ({number, children}) => {
    if (number < 4) return (
      <Row type='flex'
           justify='center'
           style={{width: '100%'}}
           className='equipment-carousel'
      >
        {children}
      </Row>
    );
    const ref = React.createRef();
    return (
      <Row type='flex' justify='center' align='middle'>
        <Col>
          <StyledIcon onClick={() => ref.current.prev()} type='left'/>
        </Col>
        <Col style={{width: '630px'}}>
          <StyledDesktopCarousel ref={ref}
                                 slidesToShow={3}
                                 dots={false}
                                 variableWidth={true}
                                 className='equipment-carousel'
          >
            {children}
          </StyledDesktopCarousel>
        </Col>
        <Col>
          <StyledIcon onClick={() => ref.current.next()} type='right'/>
        </Col>
      </Row>)
  };

  const MobileCarousel = ({children}) => (
    <StyledCarousel dots={false}
                    swipe={true}
                    variableWidth={true}
                    centerMode={true}
                    className='equipment-carousel'
    >
      {children}
    </StyledCarousel>
  );

  const getCarouselHeader = () => {
    switch (equipmentType) {
      case 'RE':
        return t('equipment-obligatory');
      case 'EX':
        return t('equipment-additional');
      case 'DE':
        return t('equipment-delivered');
    }
  };

  if (equipment.length === 0) return null;
  return (
    <Row type='flex' justify='center' style={{paddingTop: '10px'}}>
      <span style={{width: '100%', textAlign: 'center', padding: '30px 0 15px', fontWeight: '700'}}>{getCarouselHeader()}</span>
      {equipment.length === 1 ?
        <EquipmentCard product={equipment[0]}/>
          :
        windowWidth <= 750 ?
          <MobileCarousel>
            {equipment.map((item, idx) => <EquipmentCard key={idx} product={item}/>)}
          </MobileCarousel>
            :
          <DesktopCarousel number={equipment.length}>
            {equipment.map((item, idx) => <EquipmentCard key={idx} product={item}/>)}
          </DesktopCarousel>
      }
    </Row>
  )
};

const WrappedComponent = withApollo(EquipmentCards);
export default withTranslation('productView')(WrappedComponent);