import gql from 'graphql-tag';

const GET_EXPORT_TEMPLATES = gql`
  query getExportTemplates($private: Boolean) {
    me {
      exportTemplates(private: $private) {
        totalCount
        edges {
          node {
            id
            name
            brand
            createdAt
            owner {
              displayName
            }
            private
            parameters {
              edges {
                node {
                  id
                }
              }
            }
            categories {
              edges {
                node {
                  id
                }
              }
            }
            attachmentTypes
          }
        }
      }
    }
  }
`;

export default GET_EXPORT_TEMPLATES;