import gql from 'graphql-tag';

const GET_PRODUCT_KIT = gql`
  query getProductKit($id: ID!, $type: String!, $status: String!) {
    general {
      products(id: $id) {
        edges {
          node {
            id
            name
            toProduct(orderBy: "order", type: $type, status: $status) {
              totalCount
              edges {
                node {
                  id
                  fromProduct {
                    id
                    name
                    category {
                      id
                    }
                    catalogueNumber
                    currentPrice {
                      id
                      value
                    }
                    attachments(type: "P", first: 1) {
                      edges {
                        node {
                          file(width: 300)
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default GET_PRODUCT_KIT;