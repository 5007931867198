import styled from 'styled-components';
import {Button} from "antd";

const StrongButton = styled(Button)`
  width: 120px;
  &:disabled {
    background-color: lightgray !important;
  }
  
  &.slick-next {
    width: unset;
      border-radius: 8px 0 0 8px;
      background-color:  ${({theme}) => theme.color.primaryColor} !important;
      padding: 10px 20px 10px 15px;
  }
    &.slick-prev {
        width: unset;
      border-radius: 0 8px 8px 0;
      background-color:  ${({theme}) => theme.color.primaryColor} !important;
         padding: 10px 10px 10px 20px;
  }
  
  &.promotions {
    width: auto;
    padding: 9px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &.left {
        border-radius: 8px 0 0 8px;
        margin-right: 2px;
    }
    
    &.right {
        border-radius: 0 8px 8px 0;
    }
    
    &.single {
        border-radius: 8px;
        
      .anticon {
           margin-left: 15px !important;
        }
    }
    
    a {
        display: block;
        
    }
  }
    
  &.ant-btn {
    background-color: ${({theme}) => theme.color.primaryColor};
    border: none;
    color: #fff;
    
    .ant-btn-active {
      background-color: ${({theme}) => theme.color.primaryColor};
      color: #fff;
    }
    
    &:hover {
       background-color: ${({theme}) => theme.color.primaryColor};
       color: #fff;
    }
    
    &:active {
      background-color: ${({theme}) => theme.color.primaryColor};
      color: #fff;
    }
     &:focus {
      background-color: ${({theme}) => theme.color.primaryColor};
      color: #fff;
    }
  }
  
  
    h2 {
      color: #fff;
    }
  
  }
  
`;

export default StrongButton;