import React, {useContext, useEffect, useState} from 'react';
import {Query, withApollo} from 'react-apollo';
import ConstructTile from "../../../components/ConstructTile";
import PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";
import StyledTileGridLayout from "../../../components/_styledComponents/StyledTileGridLayout";

import MenuContext from "../../../context/menu/MenuContext";
import CenteredSpin from "../../../components/_styledComponents/CenteredSpin/CenteredSpin";
import PromoIcons from "../../../components/PromoIcons/PromoIcons";
import NewsIcons from "../../../components/NewsIcons/NewsIcons";
import ThemeContext from "../../../context/themeContext/themeContext";
import GET_MAIN_CATEGORIES from "../../../gql/queries/GET_MAIN_CATEGORIES";
import GET_NEW_PRODUCTS_CATEGORIES from "../../../gql/queries/GET_NEW_PRODUCTS_CATEGORIES";
import {withRouter} from 'react-router';
import {StyledLink} from "../../../components/_styledComponents";
import {Icon, message} from "antd";
import ArchiveContext from "../../../context/archive/ArchiveContext";
import ProductTile from "../../../components/ProductTile";

const ProductGridFirstPage = ({match, client, t}) => {
  const {setSelectedItem, setOpenKeys} = useContext(MenuContext);
  const [ filteredCategories, setFilteredCategories ] = useState([])
  const [ isNewView, setIsNewView ] = useState(false)
  const {brand} = useContext(ThemeContext);
  const {setOpenArchive} = useContext(ArchiveContext);

  useEffect(() => {
    setSelectedItem('products');
    setOpenKeys([]);
    match.path.includes('/new') ? setIsNewView(true) : setIsNewView(false)
    creteCategoriesForNew();
    setOpenArchive(false)
  }, []);

  const creteCategoriesForNew = () => {
    if (brand !== '') {
      client.query({
        query: GET_NEW_PRODUCTS_CATEGORIES,
        variables: {
          brand: brand
        },
        fetchPolicy: "no-cache",
      }).then(({data}) => {
        const categories = data.general.products.edges.map(product => {
          return product.node.mainCategory
        });
        const filteredCategories = categories.filter((item, i, array) => {
          return (
            i === array.findIndex((t) => ( t.id === item.id ))
          )
        });
        setFilteredCategories(filteredCategories)
      }).catch(err => {
        message.error(t('error'))
      })
    }
  }

  const NewsTile = () => (
    <ProductTile to='/new' withIcon>
      <NewsIcons brand={brand}/>
    </ProductTile>
  );

  const DiscountTile = () => (
    <ProductTile to='/promotions' withIcon>
      <PromoIcons brand={brand}/>
    </ProductTile>
  )

  return (
    <>
      {isNewView ?
        <StyledLink to='/' style={{padding: '25px 0 0 15px', marginBottom: '0', display: 'block'}}>
          <Icon type="left" style={{fontSize: '14px', marginRight: '5px'}}/>{t('categories')}
        </StyledLink> : null}

      <StyledTileGridLayout className={isNewView ? "newView" : ''}>
        {
          isNewView ? <ConstructTile newProducts categories={filteredCategories}/>
            :
            <Query query={GET_MAIN_CATEGORIES}
                   variables={{
                     brand: brand
                   }}
                   fetchPolicy='cache-first'

            >
              {({data, loading}) => {
                if (loading) return <CenteredSpin width={'100vw'} height='60vh'/>;

                return (

                  <>
                    <NewsTile/>
                    <DiscountTile/>
                    {data && <ConstructTile categories={data.general.categories.edges}/>}
                  </>
                )
              }}
            </Query>
        }

      </StyledTileGridLayout>
    </>
  );
};

ProductGridFirstPage.propTypes = {
  t: PropTypes.func.isRequired,
};

const ProductGridFirstPageWithRouter = withRouter(ProductGridFirstPage)
const TranslatedComponent = withTranslation('dashboard')(ProductGridFirstPageWithRouter);
export default withApollo(TranslatedComponent);
