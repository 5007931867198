import {
    FlexSpaceBetween,
    PromoDescription,
    StrongButton,
    StyledImgContainerPromo,
    StyledPromotionItem
} from "../_styledComponents";
import {Icon} from "antd";
import React from "react";
import {Link} from "react-router-dom";

const PromotionItem = ({promoItem, windowWidth, category, product,t, brand}) => {

    const responsiveBanners = (promotion) => {
        if (windowWidth === 'desktop' && promotion.imageDesktop) {
            return <img src={`${process.env.REACT_APP_URL}${promotion.imageDesktop}`} alt={''}/>
        } else if (windowWidth === 'tablet' && promotion.imageTablet) {
            return <img src={`${process.env.REACT_APP_URL}${promotion.imageTablet}`} alt={''}/>
        } else if (windowWidth === 'mobile' && promotion.imageMobile) {
            return <img src={`${process.env.REACT_APP_URL}${promotion.imageMobile}`} alt={''}/>
        } else return null;
    }

    const filesDataActions = (promoItem) => {
        if (promoItem.fileProductData && windowWidth) {
            return (
            <>
                <StrongButton
                    className={'promotions single'}
                    themeColor={({theme}) => theme[brand].primaryColor}
                >
                    <a href={`${process.env.REACT_APP_URL}${promoItem.fileProductData}`} target={'_blank'} download={true}>
                        {t('downloadFile')}
                        <Icon type={'download'}/>
                    </a>
                </StrongButton>
            </>
        );
    } else return null;
}

 const filesPricesActions = (promoItem) => {
        if (promoItem.fileProductPrice) {
             return (
                <>
                    <StrongButton
                        className={'promotions single'}
                        themeColor={({theme}) => theme[brand].primaryColor}
                    >
                        <a href={`${process.env.REACT_APP_URL}${promoItem.fileProductPrice}`} target={'_blank'} download={true}>
                             {t('downloadPrices')}
                            <Icon type={'download'}/>
                        </a>
                    </StrongButton>

                </>
            )
    } else return null
}
    const promotionPath = category !== '' ? `/products-list/${category}` : `product/discount/${product}`
    return (
        <StyledPromotionItem  themeColor={({theme}) => theme[brand].primaryColor}>
            <Link to={promotionPath}>
                <h2>{promoItem.title}</h2>
                <p>{promoItem.expDateFrom} - {promoItem.expDateTo}</p>
                <StyledImgContainerPromo>
                    {promoItem.description ?
                        <>
                            <PromoDescription
                                className={'desc'}
                                themeColor={({theme}) => theme[brand].primaryColor}>
                                <span>{promoItem.description}</span>
                            </PromoDescription>
                        </>
                        : null
                    }
                    {responsiveBanners(promoItem)}
                </StyledImgContainerPromo>
            </Link>
            <FlexSpaceBetween>
                <div style={{display: 'flex',  width: '50%'}}>
                    {filesDataActions(promoItem)}
                </div>
               <div style={{display: 'flex',justifyContent: 'flex-end',  width: '50%'}}>
                   {filesPricesActions(promoItem)}
                </div>
            </FlexSpaceBetween>
        </StyledPromotionItem>
    );
};

export default PromotionItem;