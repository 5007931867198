import React, {useContext} from 'react';
import ProductGeneralInfo from "../../../components/ProductGeneralInfo";
import DataPanels from "../../../components/DataPanels";
import EquipmentCards from "../../../components/EquipmentCards";
import styled from 'styled-components';

const ProductViewContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  
`;


const ProductView = ({match}) => {
  return (
    <ProductViewContainer>
      <ProductGeneralInfo category_id={match.params.category_id} id={match.params.id} />
      <DataPanels id={match.params.id}/>
      <EquipmentCards id={match.params.id} equipmentType='RE'/>
      <EquipmentCards id={match.params.id} equipmentType='EX'/>
      <EquipmentCards id={match.params.id} equipmentType='DE'/>
    </ProductViewContainer>
  )
};

export default ProductView;

