import React from 'react';
import {Collapse} from "antd";
import ParametersPanelQuery from "../ParametersPanelQuery";
import Documents from "../Documents/Documents";
import styled from 'styled-components';
import {withTranslation} from 'react-i18next';

const { Panel } = Collapse;

const  DataPanels = ({id, t}) => {

   const StyledCollapse = styled(Collapse)`
      color: ${({theme}) => theme.color.primaryColor} !important;
      
      &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
          color: inherit !important;
      }
      
      .ant-collapse-content > .ant-collapse-content-box {
        padding: 0 10px 10px;
          ${({theme}) => theme.mq.big_desktop`
             padding: 0 0 10px;
        `}
        
      }
      .ant-collapse-header {
        background-color:  #fff;
        
      }
      
       .ant-collapse-header:hover {
           ${({theme}) => theme.mq.big_desktop`
               background-color: ${({theme}) => theme.color.primaryColorLightest}
        `}
       }
       
         ${({theme}) => theme.mq.big_desktop`
          width: 80%;  
          margin: 0 auto !important;  
        `}
      
      
`;
  return (
       <StyledCollapse>
         <Panel header={<span>{t('technical-parameters')}</span>} key='2'>
           <ParametersPanelQuery id={id} type='G'/>
        </Panel>
          <Panel key='3' header={<span>{t('documents')}</span>}>
           <Documents id={id}/>
         </Panel>
         <Panel header={<span>{t('logistic-parameters')}</span>} key='1'>
          <ParametersPanelQuery id={id} type='L'/>
        </Panel>
       </StyledCollapse>
  )
};

export default withTranslation('productView')(DataPanels);