import styled from 'styled-components';

const ButtonsContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	
	 ${({theme}) => theme.mq.big_desktop`
      justify-content: space-between;
    `}
`;

export default ButtonsContainer;