export default {
  'mail': 'Email',
  'mail-err': 'Please input your email!',
  'send-mail-err': 'Error occurred when sending mail',
  'send-mail': 'Send mail',
  'email-sent': 'An email has been sent',
  'basic-err': 'Error',
  'validating-token': 'Validating token...',
  'token-invalid': 'Invalid token',
  'pass-err': 'Please input your password!',
  'too-short': 'Password is too short',
  'number': 'Number is missing',
  'lower-upper-case': 'At least one uppercase and lowercase is required',
  'doesnt-match': 'Passwords must be identical',
  'password-changed': 'Password has been changed',
  'pass-rules': 'At least 8 chars, one number, one uppercase and one lowercase',
  'password': 'Password',
  'password-conf': 'Repeat password',
  'reset': 'Reset password',
}