import gql from 'graphql-tag';

const GET_PRODUCTS = gql`
query getProducts(
  $mainCategory: String,
  $status: String,
  $new: Boolean,
  $discount: Boolean,
  $brand: String,
  $searchName: String,
  $params: [FilterProductInput],
  $models: [FilterProductModelsInput],
  $maxPrice: Float,
  $minPrice: Float,
  $first: Int,
  $catalogueNumber: String,
  $searchText: String,
  $after: String,
) {
  general {
    products(
      params: $params,
      models: $models,
      status_In: $status,
      mainCategory: $mainCategory,
      new: $new,
      discount: $discount,
      brand: $brand,
      name_Icontains: $searchName,
      priceValue_Gte: $minPrice,
      priceValue_Lte: $maxPrice,
      first: $first,
      catalogueNumber_Icontains: $catalogueNumber,
      search: $searchText,
      after: $after,
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          name
          id
          catalogueNumber
          ean
          efficiencyImages
          category {
            id
          }
          mainCategory {
           id
           name
         }
          noPrice
          noPriceComment
          currentPrice {
            id
            value
            promotion
          }
          previousPrice {
            id
            value
            promotion
          }
          descriptionShort
          attachments(type: "P", first: 1) {
            edges {
              node {
                file (width: 300)
              }
            }
          }
        }
      }
    }
  }
}
`;

export default GET_PRODUCTS;