import gql from 'graphql-tag';

const GET_PARAMETERS = gql`
    query getParameters($id: ID, $type: String) {
      general {
        products(id: $id, first: 1) {
          edges {
            node {
              id
              productparameterSet(
                parameter_ParameterType: $type,
              ) {
                edges {
                  node {
                    id
                    textValue
                    numberValue
                    numberValueTo
                    arrayValue
                    parameter {
                      id
                      name
                      type
                      unit
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
`;

export default GET_PARAMETERS;