import styled from 'styled-components';

const ProductBasicInfoCont = styled.div`
	padding: 0 15px;
	text-align: left;
	h3 {
	  font-weight: 700;
	  margin-bottom: 0;
	  line-height: 16px;
	  
	   ${({theme}) => theme.mq.big_desktop`
      margin-bottom: 15px; 
  `}
	}
	
	p.description {
	    width: 90%;
      text-align: justify;
      line-height: 14px;
      font-size: 14px;
      padding: 0 0 10px;
      color: rgba(0, 0, 0, .65);
      margin-bottom: 0;
      
      ${({theme}) => theme.mq.big_desktop`
				width: 100%;
    `}
	}
	
	p.number {
	  font-size: 14px;
	  margin-bottom: 0;
	  color:  ${({theme}) => theme.brandlessColors.darkGrey};
	  
	    ${({theme}) => theme.mq.big_desktop`
        margin-bottom: 15px; 
    `}
	}
	
	
	
	 ${({theme}) => theme.mq.big_desktop`
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     width: 70%;
     padding: 0;
  `}
`;

export default ProductBasicInfoCont;