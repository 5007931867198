import styled from 'styled-components';

const SearchContainer = styled.div`
  padding: 10px;
  max-height: ${({theme}) => `calc(50vh - ${theme.layout.headerHeight})!important}`};
  
 
  ${({theme}) => theme.mq.big_desktop`
    width: 350px;
    max-height: ${({theme}) => `calc(100vh - ${theme.layout.headerHeight})!important}`};
    background-color: #fff;
    height: 1px;
    line-height: 32px !important;
    
    ul {
      padding: 10px;
    }                   
  `}
  
  
  .list-container {
    box-shadow: none;
    
    ${({theme}) => theme.mq.big_desktop`
      width: calc(100% - 2px);
      max-height: 80vh;
      overflow: auto;
      background-color: #fff;
      box-shadow: 1px 0 3px 2px #8885;
    `}
  }
`;

export default SearchContainer;