import gql from 'graphql-tag';

const GENERAL_USER_DATA = gql`
	query {
    me {
      user {
        id
        username
        role
        firstName
        lastName
        email
        taxId
        companyName
        phoneNumber
      }
    }
  }
`;

export default GENERAL_USER_DATA;