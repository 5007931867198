import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";

import styled from "styled-components";

import routes from "../../config/routes";

import {uppercaseFirstLetter} from "../../helpers/uppercaseFirstLetter";

const StyledBreadcrumbWrapper = styled.div`
  min-height: 40px;
  margin-bottom: 30px;

  a {
    color: ${({theme}) => theme.color.primaryColor};
    font-weight: 600;
    font-family: Arial;
  }

  a:hover {
    color: ${({theme}) => theme.color.green};
  }
`;

const StyledBreadcrumb = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const StyledPrimaryLink = styled(Link)`
  margin: 10px;
`;

const StyledSecondaryLink = styled(Link)`
  margin: 10px;
`;

const StyledDivider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BreadcrumbComponent = ({categories, t}) => {
  const createBreadcrumb = () => {
    if (categories.length > 0) {
      const paths = categories[ 0 ].node.pathToRoot.slice(0, -1);

      return paths.map((singlePathData) => (
        <>
          <StyledSecondaryLink key={singlePathData.id} to={singlePathData.id}>
            {singlePathData.displayName || singlePathData.name}
          </StyledSecondaryLink>
          <StyledDivider>/</StyledDivider>
        </>
      ));

    }
    return null;
  };

  useEffect(() => {
    createBreadcrumb();
  }, [ categories ]);

  return (
    <StyledBreadcrumbWrapper>
      <StyledBreadcrumb>
        <StyledPrimaryLink to={routes.PRODUCTS_GRID}>
          {uppercaseFirstLetter(t("products"))}
        </StyledPrimaryLink>
        <StyledDivider>/</StyledDivider>
        {createBreadcrumb()}
      </StyledBreadcrumb>
    </StyledBreadcrumbWrapper>
  );
};

BreadcrumbComponent.propTypes = {
  categories: PropTypes.array.isRequired,
};

const TranslatedBreadcrumb = withTranslation("navigation")(BreadcrumbComponent);

export default TranslatedBreadcrumb;
