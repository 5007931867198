import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Modal, Button, Row, Divider, Col, Input, Select, Checkbox, Spin, message} from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {withApollo} from 'react-apollo';
import Cookies from 'universal-cookie';

import GET_ATTACHMENT_TYPES from "../../gql/queries/GET_ATTACHMENT_TYPES";
import GET_BRANDS from "../../gql/queries/GET_BRANDS";
import GET_CATEGORIES from "../../gql/queries/GET_CATEGORIES";
import GET_BRAND_ROOT_CATEGORY from "../../gql/queries/GET_BRAND_ROOT_CATEGORY";
import GET_CATEGORY_PARAMETERS from "../../gql/queries/GET_CATEGORY_PARAMETERS";
import EXPORT_TEMPLATE_MUTATION from "../../gql/mutations/EXPORT_TEMPLATE_MUTATION";
import GET_EXPORT_TEMPLATES from "../../gql/queries/GET_EXPORT_TEMPLATES";

const StyledSpan = styled.span`
  text-align: center;
  font-size: 0.8em;
`;

message.config({maxCount: 1});

const cookies = new Cookies();

class DataExportModal extends Component {
  constructor(props) {
    super(props);
    this.paramSelectRef = React.createRef();
    this.state = {
      loadingExport: false,
      loadingSaveAndExport: false,
      modalVisible: false,
      action: '',
      parametersDisabled: true,
      categoriesDisabled: true,
      submitDisabled: true,
      attachmentsVisible: false,
      template: undefined,
      allBrands: [],
      allCategories: [],
      allParameters: [],
      attachmentTypes: [],
      rootCategory: '',
      fields: {
        id: '',
        name: '',
        brand: '',
        categories: [],
        parameters: [],
        attachments: [],
      }
    }
  }

  componentDidMount() {
    const {client} = this.props;
    client.query({query: GET_BRANDS}).then(res => {
      this.setState({allBrands: res.data.general.brands})
    });
    client.query({query: GET_ATTACHMENT_TYPES}).then(res => {
      let choices = [];
      for (let item of res.data.general.attachmentTypeValues) {
        choices.push({key: item[0], display: item[1]})
      }
      this.setState({attachmentTypes: choices}, () => {
        const fields = this.state.fields;
        fields.attachments = this.state.attachmentTypes.map(item => item.key);
        this.setState({fields});
      });
    });
  }

  setDefaultValues = async () => {
    const fields = this.state.fields;
    const {template} = this.state;
    fields.id = template.id;
    fields.name = template.name;
    fields.brand = template.brand;
    await this.setState({fields}, () => {
      this.getRootCategory();
      this.getCategories().then(() => {
        const categories = template.categories.edges.map(edge => edge.node.id);
        fields.categories = categories.map(id => this.state.allCategories.map(obj => obj.id).indexOf(id));
        this.setState({fields}, () => {
          this.getParameters().then(() => {
            fields.parameters = template.parameters.edges.map(edge => edge.node.id);
            this.setState({fields, submitDisabled: false});
          });
        });
      });
    });
    if (template.attachmentTypes) {
      fields.attachments = template.attachmentTypes;
      await this.setState({fields}, () => this.setState({attachmentsVisible: true}));
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props && this.props.visible)
      this.setState({
        action: this.props.action,
        template: this.props.template,
      }, () => {
        if (this.props.visible && this.state.template)
          this.setDefaultValues().then(() => {this.setState({modalVisible: this.props.visible})});
        else this.setState({modalVisible: this.props.visible});
      });
  }

  destroyModal = () => {
    let fields = {
      id: '',
      name: '',
      brand: '',
      categories: [],
      parameters: [],
      attachments: this.state.attachmentTypes.map(item => item.key),
    };
    this.setState({modalVisible: false}, () => {
      this.setState({
        fields,
        loadingExport: false,
        loadingSaveAndExport: false,
        parametersDisabled: true,
        categoriesDisabled: true,
        submitDisabled: true,
        attachmentsVisible: false,
        template: undefined,
        allCategories: [],
        allParameters: [],
        rootCategory: '',
      }, () => this.props.hideModal())
    });
  };

  fileRequest = async () => {
    let body = {
      export_type: 'instant',
      brand: this.state.fields.brand,
      categories: this.state.fields.categories.map(id => this.state.allCategories[id].id),
      parameters: this.state.fields.parameters,
      name: this.state.fields.name,
      status: 'P',
    };
    if (this.state.attachmentsVisible) body.attachments = this.state.fields.attachments;
    await fetch(process.env.REACT_APP_URL_EXPORT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${cookies.get('jwttoken')}`,
      },
      body: JSON.stringify(body)
    }).then(response => {
      if (!response.ok) throw response;
      return true;
    }).then(res => {
      message.success(this.props.t('confirmExportMessageContent'))
    }).catch(err => {
      message.error(this.props.t('base-error'));
    });
  };

  execMutation = async (privateTemplate = true) => {
    const {client, t} = this.props;
    let mutationInput = {
      name: this.state.fields.name,
      private: privateTemplate,
      brand: this.state.fields.brand,
      categories: this.state.fields.categories.map(id => this.state.allCategories[id].id),
      parameters: this.state.fields.parameters,
    };
    if (this.state.action === 'edit') mutationInput.id = this.state.fields.id;
    if (this.state.attachmentsVisible) mutationInput.attachments = this.state.fields.attachments;
    await client.mutate({
      mutation: EXPORT_TEMPLATE_MUTATION,
      variables: {
        input: mutationInput
      },
      update: async (store, {data: {exportTemplateMutation}}) => {
        const data = store.readQuery({
          query: GET_EXPORT_TEMPLATES,
          variables: {
            private: privateTemplate
          }
        });
        let index = data.me.exportTemplates.edges.map(edge => edge.node.id).indexOf(exportTemplateMutation.template.id);
        if (index >= 0) data.me.exportTemplates.edges[index].node = exportTemplateMutation.template;
        else {
          data.me.exportTemplates.totalCount += 1;
          data.me.exportTemplates.edges.push({
            node: exportTemplateMutation.template,
            __typename: 'ExportTemplateModelTypeEdge'
          });
        }
        await store.writeQuery({
          query: GET_EXPORT_TEMPLATES,
          variables: {
            private: privateTemplate
          },
          data
        });
      }
    }).then(res => {
      if (res.data.exportTemplateMutation.ok) message.success(t('export-template-success-message'));
      else message.error(t('base-error'));
    });
  };

  buttonAction = async (download, save) => {
    if (download) await this.fileRequest();
    if (save) await this.execMutation();
    if (this.state.loadingExport) this.setState({loadingExport: false});
    if (this.state.loadingSaveAndExport) this.setState({loadingSaveAndExport: false});
    this.destroyModal();
  };

  buttons = () => {
    const {t} = this.props;
    return {
      export:
        this.state.loadingExport ?
          <Button disabled key='instant-disabled'
                  style={{width: '7em'}}
          >
            <Spin size='small'/>
          </Button>
            :
          <Button onClick={() => {
              this.setState({loadingExport: true});
              this.buttonAction(true, false);
            }}
                disabled={this.state.submitDisabled}
                key='instant'
                style={{width: '7em'}}
          >
            {t('export')}
          </Button>,
      save:
        <Button onClick={() => this.buttonAction(false, true)}
                disabled={this.state.submitDisabled}
                key='save'
        >
          {t('save')}
        </Button>,
      saveAndExport:
        this.state.loadingSaveAndExport ?
          <Button disabled key='save-and-export-disabled'
                  style={{width: '10em'}}
          >
            <Spin size='small'/>
          </Button>
            :
          <Button onClick={() => {
              this.setState({loadingSaveAndExport: true});
              this.buttonAction(true, true);
            }}
                  disabled={this.state.submitDisabled}
                  key='save-and-export'
                  style={{width: '10em'}}
          >
          {t('save-and-export')}
        </Button>,
      duplicate:
        <Button onClick={() => this.buttonAction(false, true)}
                disabled={this.state.submitDisabled}
                key='duplicate'
        >
          {t('duplicate-button')}
        </Button>,
      duplicateAndExport:
        this.state.loadingSaveAndExport ?
          <Button disabled key='duplicate-and-export-disabled'
                  style={{width: '11em'}}
          >
            <Spin size='small'/>
          </Button>
            :
          <Button onClick={() => {
              this.setState({loadingSaveAndExport: true});
              this.buttonAction(true, true);
            }}
                  disabled={this.state.submitDisabled}
                  key='duplicate-and-export'
                  style={{width: '11em'}}
          >
            {t('duplicate-and-export')}
          </Button>,
      edit:
        <Button onClick={() => this.buttonAction(false, true)}
                disabled={this.state.submitDisabled}
                key='edit'
        >
          {t('save')}
        </Button>,
      editAndExport:
        this.state.loadingSaveAndExport ?
          <Button disabled key='edit-and-export-disabled'
                  style={{width: '10em'}}
          >
            <Spin size='small'/>
          </Button>
            :
          <Button onClick={() => {
              this.setState({loadingSaveAndExport: true});
              this.buttonAction(true, true);
            }}
                  disabled={this.state.submitDisabled}
                  key='edit-and-export'
          >
            {t('save-and-export')}
          </Button>,
      cancel:
        <Button onClick={() => this.destroyModal()}
                key='cancel'
        >
          {t('cancel')}
        </Button>,
      }
  };

  cases = () => {
    const {t} = this.props;
    const buttons = this.buttons();
    return {
      instant: {
        buttons: [buttons.export, buttons.saveAndExport, buttons.cancel],
        title: t('export'),
      },
      normal: {
        buttons: [buttons.save, buttons.saveAndExport, buttons.cancel],
        title: t('create-template'),
      },
      edit: {
        buttons: [buttons.edit, buttons.editAndExport, buttons.cancel],
        title: t('edit-export-template'),
      },
      duplicate: {
        buttons: [buttons.duplicate, buttons.duplicateAndExport, buttons.cancel],
        title: t('duplicate-export-template')
      },
    }
  };

  renderTitle = () => {
    const {t} = this.props;
    return [
      <Row key={0} type='flex' justify='center'>
        <h3 style={{fontWeight: 'bold'}}>{this.cases()[this.state.action].title}</h3>
      </Row>,
      <Row key={1} type='flex' justify='center'>
        <StyledSpan>{t('export-description-1')}</StyledSpan>
        <StyledSpan>{t('export-description-2')}</StyledSpan>
      </Row>
    ];
  };

  validateFields = () => {
    const fields = this.state.fields;
    const condition = fields.name && fields.parameters.length;
    if (condition && this.state.submitDisabled)
      this.setState({submitDisabled: false});
    else if (!condition && !this.state.submitDisabled)
      this.setState({submitDisabled: true});
  };

  onNameChange = (value) => {
    const fields = this.state.fields;
    fields.name = value;
    this.setState({fields}, () => this.validateFields());
  };

  getCategories = async () => {
    const {client} = this.props;
    await client.query({
      query: GET_CATEGORIES,
      variables: {
        brand: this.state.fields.brand,
        main: true,
      }
    }).then(res => this.setState({
      allCategories: res.data.general.categories.edges.map(edge => edge.node),
      categoriesDisabled: false,
    }));
  };

  getRootCategory = () => {
    const {client} = this.props;
    client.query({
      query: GET_BRAND_ROOT_CATEGORY,
      variables: {brand: this.state.fields.brand}
    }).then(res => {
      this.setState({rootCategory: res.data.general.categories.edges[0].node});
    });
  };

  onBrandChange = (value) => {
    const fields = this.state.fields;
    fields.brand = value;
    if (fields.categories.length) {
      fields.categories = [];
      fields.parameters = [];
      this.setState({parametersDisabled: true});
    }
    this.setState({fields}, () => {
      this.getRootCategory();
      this.getCategories();
    });
  };

  getParameters = async () => {
    let newParameters = [];
    const {client} = this.props;
    const categories = this.state.fields.categories.map(idx => this.state.allCategories[idx]);
    const queryCategories = [this.state.rootCategory, ...categories];
    await client.query({
      query: GET_CATEGORY_PARAMETERS,
      variables: {categoryIds: queryCategories.map(obj => obj.id)},
    }).then(res => {
      for (let edge of res.data.general.parameters.edges) {
        const parameter = edge.node;
        let presentCategories = [];
        for (let categoryEdge of parameter.category.edges) {
          const category = categoryEdge.node;
          if (queryCategories.map(selectedCategory => selectedCategory.id).indexOf(category.id) >= 0)
            presentCategories.push(category);
        }
        const newParameter = {
          id: parameter.id,
          name: parameter.name,
          categories: presentCategories.map(category => category.displayName || category.name)
        };
        newParameters.push(newParameter);
      }
    });
    this.setState({allParameters: newParameters}, () => {
      if (this.state.parametersDisabled) this.setState({parametersDisabled: false});
      const fields = this.state.fields;
      fields.parameters = fields.parameters.filter(val => newParameters.map(obj => obj.id).includes(val));
      this.setState({fields});
    });
  };

  onCategorySelect = (value) => {
    const fields = this.state.fields;
    fields.categories.push(value);
    this.setState({fields}, () => this.getParameters());
  };

  onCategoryDeselect = (value) => {
    const fields = this.state.fields;
    const idx = fields.categories.indexOf(value);
    fields.categories.splice(idx, 1);
    if (!fields.categories.length) {
      this.paramSelectRef.current.blur();
      fields.parameters = [];
      this.setState({parametersDisabled: true, fields});
    }
    else this.setState({fields}, () => this.getParameters());
  };

  onParametersChange = (value) => {
    const fields = this.state.fields;
    fields.parameters = value;
    this.setState({fields}, () => this.validateFields());
  };

  onAttachmentsChange = (value) => {
    const fields = this.state.fields;
    fields.attachments = value;
    this.setState({fields});
  };

  renderModalContents = () => {
    const {t} = this.props;
    return (
      <>
        <Row type='flex' align='middle'>
          <Col span={5}>{t('template-name')}</Col>
          <Col span={8}>
            <Input name={'name'}
                   value={this.state.fields.name}
                   onChange={(e) => this.onNameChange(e.target.value)}
            />
          </Col>
          <Col span={1}>
            <Divider type='vertical' style={{marginLeft: '20px'}}/>
          </Col>
          <Col span={2}>
            {t('brand')}
          </Col>
          <Col span={8}>
            <Select value={this.state.fields.brand}
                    name='brand'
                    style={{width: '100%'}}
                    onSelect={(value) => this.onBrandChange(value)}
            >
              {this.state.allBrands.map((obj, idx) => (
                <Select.Option value={obj[0]} key={idx}>
                  {obj[1]}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Divider/>
        <Row type='flex' align='middle'>
          <Col span={5}>
            {t('template-categories')}
          </Col>
          <Col span={19}>
            <Select disabled={this.state.categoriesDisabled}
                    value={this.state.fields.categories}
                    name='categories'
                    optionFilterProp="children"
                    mode='multiple'
                    style={{width: '100%'}}
                    onSelect={(value) => this.onCategorySelect(value)}
                    onDeselect={(value) => this.onCategoryDeselect(value)}
            >
              {this.state.allCategories.map((obj, idx) => (
                <Select.Option value={idx} key={idx}>
                  {obj.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row style={{marginTop: '15px'}} type='flex' align='middle'>
          <Col span={5}>
            {t('template-parameters')}
          </Col>
          <Col span={19}>
            <Select disabled={this.state.parametersDisabled}
                    value={this.state.fields.parameters}
                    name='parameters'
                    optionFilterProp="children"
                    mode='multiple'
                    style={{width: '100%'}}
                    onChange={(value) => this.onParametersChange(value)}
                    ref={this.paramSelectRef}
            >
              {this.state.allParameters.map((obj, idx) => (
                <Select.Option value={obj.id} key={idx}>
                  {obj.name} ({obj.categories.join(', ')})
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row style={{marginTop: '15px'}}>
          <Checkbox checked={this.state.attachmentsVisible}
                    onChange={(e) => this.setState({attachmentsVisible: e.target.checked},
                        () => this.setState({attachmentsVisible: e.target.checked}))}
          >
            {t('include-attachments')}
          </Checkbox>
        </Row>
        <Row style={{display: this.state.attachmentsVisible ? 'block' : 'none', marginTop: '10px'}}>
          <Row type='flex' align='middle'>
            <Col span={5}>
              {t('attachment-types')}
            </Col>
            <Col span={19}>
              <Select mode='multiple'
                      style={{width: '100%'}}
                      value={this.state.fields.attachments}
                      onChange={(e) => this.onAttachmentsChange(e)}
              >
                {this.state.attachmentTypes.map((obj, idx) => (
                  <Select.Option value={obj.key} key={idx}>
                    {obj.display}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        </Row>
      </>
    )
  };

  render() {
    const cases = this.cases();
    return (
      <Modal visible={this.state.modalVisible}
             width={900}
             onCancel={() => this.destroyModal()}
             destroyOnClose={true}
             footer={this.state.action ? cases[this.state.action].buttons : null}
             maskClosable={false}
             title={this.state.action ? this.renderTitle() : null}
      >
        {this.renderModalContents()}
      </Modal>
    )
  }
}

DataExportModal.propTypes = {
  action: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  template: PropTypes.object,
  hideModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const DataExportModalWrapper = withApollo(DataExportModal);

export default withTranslation('dataExport')(DataExportModalWrapper);
