import React from 'react';
import ProfileForm from "../../../components/ProfileForm";
import styled from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
   
	 ${({theme}) => theme.mq.big_desktop`
      padding-top: 30px; 
  `};
	 
`;

const StyledSpan = styled.span`
  display: flex;
  align-items: center;
  font-size: ${({theme}) => theme.font.m};
  margin: 15px 0;
  
   ${({theme}) => theme.mq.big_desktop`
        margin: 0 0 15px;
  `};
`;

const ProfileContainer = () => (
  <StyledContainer>
    <StyledSpan>Edycja profilu</StyledSpan>
    <ProfileForm/>
  </StyledContainer>
);

export default ProfileContainer;