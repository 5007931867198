import gql from 'graphql-tag';

const GET_DEFAULT_CATEGORY_IMAGE = gql`
    query getDefaultCategoryImage {
        general{
            categoryDefaultImage
        }
    }
`;

export default GET_DEFAULT_CATEGORY_IMAGE;
