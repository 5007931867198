import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {Spin} from 'antd';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({height}) => height && height};
  width: ${({width}) => width && width};
`;

const CenteredSpin = (props) => (
  <Wrapper {...props}>
    <Spin/>
  </Wrapper>
);

CenteredSpin.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default CenteredSpin;