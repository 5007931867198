import styled from 'styled-components';
import {Form} from 'antd';

const StyledProfileForm = styled(Form)`
	width: 80%;
	
	.ant-form-item {
	  margin-bottom: 10px;
	}
	
	.ant-form-item-label {
	  padding: 0 !important;
	}
`;

export default StyledProfileForm;