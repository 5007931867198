import {css} from 'styled-components';

const sizes = {
  bigger_desktop: 1366,
  big_desktop: 1200,
  desktop: 992,
  tablet: 768,
  phone: 576,
  smallMobile: 360,
};

const mq = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;

  return acc
}, {});

const brandlessColors = {
  primaryColorLight: '#999999',
  lightGrey: '#E1E1E1',
  darkGrey: '#AEAEAE',
};

const theme = {
  mq,
  color: {
    white: '#fff',
    gray: '#f0f0f0',
    grayLight: '#f5f5f5',
  },
  brandlessColors: brandlessColors,
  layout: {
    containerWidth: '1400px',
    headerHeight: '64px',
    logoHeight: '36px',
    modalFullHeight: '80vh',
    footerHeight: '83px',
  },
  font: {
    s: '0.75rem',
    m: '1rem',
    l: '1.25rem',
    normal: '400',
    bold: '600',
    thin: '300'
  },
  zIndex: {
    modal: 30,
    navigation: 20,
    menu: 10,
    share: 1,
  },
};

const saunierTheme = {
  ...theme,
  color: {
    ...theme.color,
    primaryColorLightest: '#ffeff3',
    primaryColorLight: '#ececec',
    primaryColor: '#cc0033',
    primaryColorDark: '#990026',
    primaryColorRgba: 'rgba(204, 0, 51, .5)',
    tileColor: '#ca0d25',
    tileColorHover: 'rgba(202, 13, 37, .9)',
    newsLabel: '#00aadb',
    promoLabel: '#d4082c',
    promoLabelLight: 'rgba(212, 8, 44, .7)',
    table: '#ececec',
    carousel: '#fff',
    }
};

const vaillantTheme = {
  ...theme,
  color: {
    ...theme.color,
    primaryColorLightest: '#eefdf6',
    primaryColorLight: '#c2e3e0',
    primaryColor: '#00917e',
    primaryColorDark: '#006659',
    primaryColorRgba: 'rgba(0, 145, 126, .5)',
    tileColor: '#00917d',
    tileColorHover: 'rgba(0, 145, 125, .9)',
    newsLabel: '#38a48e',
    promoLabel: '#f39323',
    promoLabelLight: 'rgba(243, 147, 35, .7)',
    table: '#ebf3f1',
    carousel: '#d9e8d3',
  }
};

export default theme;
export {saunierTheme, vaillantTheme};
