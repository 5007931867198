import styled from 'styled-components';
import {Tag} from "antd";

const { CheckableTag } = Tag;

const StyledFilterTag = styled(CheckableTag)`
  margin: 2px !important;
  transition: .3s;
  white-space: normal !important;
  
  &:hover {
    background-color: transparent;
    color: #888;
  }

  &.ant-tag:hover {  
    ${({theme}) => theme.mq.big_desktop`
       background-color: ${({theme}) => theme.color.white};
      color: ${({theme}) => theme.color.primaryColor};
      border: 1px solid ${({theme}) => theme.color.primaryColor};
      cursor: pointer;
  `}
  }
  
  
  &.ant-tag-checkable-checked:hover {
  
    	 ${({theme}) => theme.mq.big_desktop`
         background-color: ${({theme}) => theme.color.primaryColor};
        color: ${({theme}) => theme.color.white};
        cursor: pointer;
    `}
  }
  &.ant-tag-checkable:active {
     background-color: ${({theme}) => theme.color.primaryColor} !important;
  }
  
  &.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
    
     ${({theme}) => theme.mq.big_desktop`
           color: ${({theme}) => theme.color.primaryColor};
    `}
    
  }
  
   &.ant-tag-checkable-checked {
    background-color: ${({theme}) => theme.color.primaryColor};
    color: #fff;
    
  }
 
`;

export default StyledFilterTag;