import styled from 'styled-components';
import {Icon} from "antd";

const NewLabel = styled(Icon)`
   position: absolute;
   top: 0;
   font-size: 16px;
   padding: 5px;
   right: 10px;
	 color: #fff !important;
	 background-color: ${props => props.colorSet} !important;
	 border-radius: 50%;
`;

export default NewLabel;