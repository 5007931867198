import styled from 'styled-components';

const StyledListContainer = styled.div`
  display: flex;
  flex-direction: column;
	width: 100%;
	
	ul {
		padding: 0;
	}
	
	
	 ${({theme}) => theme.mq.big_desktop`    
	      ul {
	        padding: 0 15px;
	        width: 100%;
	      }
    `}
`;

export default StyledListContainer;