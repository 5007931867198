import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {CategoryIconWrapper} from "../_styledComponents";
import {Link} from "react-router-dom";
import routes from "../../config/routes";

const StyledTile = styled.section`
  background: ${({theme}) =>
          `linear-gradient(to right, ${theme.color.primaryColor} 0%,${theme.color.primaryColorDark} 100%)`};
  background-image: ${({image}) => image && `url("${image}")`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: ${({hasLabel}) => ( hasLabel ? "flex-end" : "center" )};
  position: relative;
  height: ${({withIcon}) => ( withIcon ? " 200px" : "150px" )};

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0;
    transition: 0.3s;
  }

  &:hover {
    &:after {
      opacity: 0.2;
    }
  }
`;

const StyledLabel = styled.div`
  color: ${({theme}) => theme.color.gray};
  z-index: 1;
  font-weight: 600;
  font-size: 0.75rem;
  letter-spacing: 0.3px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 10px;

  display: -webkit-box;
  max-width: 233px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const StyledLink = styled(Link)`
  background-color: #999;
`;
const StyledLabelWrapper = styled.div`
  height: 50px;
  background: ${({theme}) =>
          `linear-gradient(to right, ${theme.color.primaryColor} 0%,${theme.color.primaryColorDark} 100%)`};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProductTile = ({
                        category,
                        newProducts,
                        to,
                        children,
                        imageFallback,
                        withIcon,
                      }) => {
  const getTo = () => {
    if (to) return to;
    if (category.isLeaf) return `${routes.PRODUCTS_LIST}/${category.id}`;
    if (newProducts)
      return `${routes.PRODUCTS_LIST}/${routes.NEW}/${category.id}`;
    return `${routes.PRODUCTS_GRID}/${category.id}`;
  };

  const image = category.image || imageFallback;
  const imageUrl =
    image && !withIcon ? `${process.env.REACT_APP_URL}${image}` : null;
  const label = category.displayName || category.name;

  return (
    <StyledLink className="wrapper" to={getTo()}>
      <StyledTile
        withIcon={withIcon}
        tileHeight={220}
        image={imageUrl}
        hasLabel={!!label}
      >
        {withIcon && <CategoryIconWrapper>{children}</CategoryIconWrapper>}
      </StyledTile>

      {label && (
        <StyledLabelWrapper>
          <StyledLabel>{label}</StyledLabel>
        </StyledLabelWrapper>
      )}
    </StyledLink>
  );
};

ProductTile.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }),
  to: PropTypes.string,
  newProducts: PropTypes.bool,
  withIcon: PropTypes.bool,
  imageFallback: PropTypes.string,
};

ProductTile.defaultProps = {
  category: {},
  withIcon: false,
  newProducts: false,
  imageFallback: null,
  to: null,
};

export default ProductTile;
