import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {withTranslation} from 'react-i18next';
import {Row, Col, Divider, Button, Icon, message} from 'antd';
import DataExportCards from "../../../components/DataExportCards";
import DataExportModal from "../../../components/DataExportModal";

const StyledDiv = styled.div`
  padding: 15px 25px; 
  span {
    font-size: ${({theme}) => theme.font.m};
  }
  
   ${({theme}) => theme.mq.big_desktop`
      padding: 30px 25px 25px;
      max-width: 1000px;
    `}
`;

const StyledIcon = styled(Icon)`
  font-size: 1.2em;
`;

const StyledTitle = styled.h3`
  text-transform: uppercase;
  margin-bottom: unset !important;
`;

const StyledButton = styled(Button)`
  padding: 0 10px !important;
`;

const HeadRow = ({children}) => (
  <Row type='flex'
       align='middle'
       justify='space-between'
       style={{ marginBottom: '15px'}}
  >
    {children}
  </Row>
);


const DataExport = ({t}) => {
  const [modalAction, setModalAction] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [handledTemplate, setHandledTemplate] = useState(undefined);

  const exportModal = (action, template) => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width < 900) message.warning(t('screen-too-small'));
    else {
      setModalAction(action);
      setModalVisible(true);
      setHandledTemplate(template);
    }
  };

  return (
    <StyledDiv>
      <HeadRow>
        <Col span={15}>
          <StyledTitle>{t('data-export')}</StyledTitle>
        </Col>
        <Col>
          <StyledButton onClick={() => exportModal('instant')}>
            <StyledIcon type="export" />
          </StyledButton>
        </Col>
      </HeadRow>
      <Divider style={{marginTop: '10px'}}/>
      <HeadRow>
        <Col span={15}>
         <HeadRow>{t('your-templates')}</HeadRow>
        </Col>
        <Col>
          <StyledButton onClick={() => exportModal('normal')}>
            <StyledIcon type='plus' />
          </StyledButton>
        </Col>
      </HeadRow>
      <DataExportCards privateTemplates={true}
                       setModalVisible={setModalVisible}
                       setHandledTemplate={setHandledTemplate}
                       setModalAction={setModalAction}
      />
      <Divider/>
      <HeadRow>{t('public-templates')}</HeadRow>
      <DataExportCards privateTemplates={false}
                       setModalVisible={setModalVisible}
                       setHandledTemplate={setHandledTemplate}
                       setModalAction={setModalAction}
      />
      <DataExportModal action={modalAction}
                       visible={modalVisible}
                       template={handledTemplate}
                       hideModal={() => setModalVisible(false)}
      />
    </StyledDiv>
  )
};

DataExport.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('dataExport')(DataExport);