import styled from 'styled-components';

const StyledGrid = styled.div`
	
	 ${({theme}) => theme.mq.big_desktop`
        display: grid;
        height: 100%;
        grid-template-columns: ${isPromotion => isPromotion ?`510px 1fr` :  `2fr 1fr`};
    `}
`;

export default StyledGrid;