import React from 'react';
import {Icon} from "antd";
import {ImageWrapper, NewLabel, PromoLabel} from "../_styledComponents";

const AvatarPlaceholder = ({file, category}) => {

 const fileDisplay =  file.map(f => f.node.file);

 const renderLabel = () => {
   if (category === 'new') {
     return <NewLabel type="fire" colorSet={({theme}) => theme.color.newsLabel}/>
   } else if (category === 'discount') {
     return   <PromoLabel type="percentage" colorSet={({theme}) => theme.color.promoLabel}/>
   } else return null
 };

  return (
    <ImageWrapper category={category}>
      {renderLabel()}
      {

          file.length === 0 ?
          <Icon type="picture" className='placeholder' />
          :
            <img src={`${process.env.REACT_APP_URL}${fileDisplay[0]}`} alt='img'/>
      }
    </ImageWrapper>
  )
};

export default AvatarPlaceholder;