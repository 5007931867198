export default {
  'value': 'Wartość',
  'unit': 'Jednostka',
  'equipment-obligatory': 'Wyposażenie obowiązkowe',
  'equipment-additional' : 'Wyposażenie dodatkowe',
  'equipment-delivered' : 'Produkty wchodzące w skład dostawy',
  'product-code': 'Numer katalogowy',
  'no-price': 'Brak ceny',
  'yes' : 'Tak',
  'no' : 'Nie',
  'technical-parameters': 'Parametry techniczne',
  'documents': 'Dokumenty',
  'logistic-parameters': 'Parametry logistyczne',
};