import React from 'react';
import styled from "styled-components";

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`
const StyledHeader = styled.h1`
  text-align: center;
  font-weight: 600;
  font-family: Arial;
`

const SomethingWentWrong = () => (
  <StyledWrapper>
    <div>
      <StyledHeader>Coś poszło nie tak!</StyledHeader>
      <p>Odśwież stronę lub wróc do <a href='/'>strony głownej</a></p>
    </div>
  </StyledWrapper>
);


export default SomethingWentWrong;
