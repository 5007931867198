import gql from 'graphql-tag';

const GET_BRAND_ROOT_CATEGORY = gql`
  query RootCategory($brand: String!) {
    general {
      categories(brand: $brand, parent_Isnull: true) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

export default GET_BRAND_ROOT_CATEGORY;