import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import UpdateProvider from "./context/update/UpdateProvider";

let updateRef = null;

ReactDOM.render(
    <UpdateProvider onRef={(update)=> updateRef=update }>
        <App />
    </UpdateProvider>
    , document.getElementById('root')
);

// If you want your app o work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

if(process.env.NODE_ENV !== 'development' || process.env.REACT_APP_FORCE_SW){
  serviceWorker.register(
      {
          onUpdate: (skipWaiting)=>{
              if (updateRef) updateRef(()=>skipWaiting())
          }
      }
  );
} else {
  serviceWorker.unregister()
}

