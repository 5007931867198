import React, {useEffect, useState} from 'react';
import {Button, Col, Drawer, Row} from 'antd';
import PropTypes from 'prop-types';
import UpdateContext from "./UpdateContext";


const UpdateProvider = ({children, onRef}) => {
    const [showUpdatePopup, setShowUpdatePopup] = useState(false);

    useEffect(() => {
        onRef(setShowUpdatePopup);
        return () => onRef(null);
    }, []);

    return (
        <UpdateContext.Provider value={{showUpdatePopup, setShowUpdatePopup}}>
            {children}
            <Drawer
                id={'update-drawer'}
                title={'New update!'}
                placement={'bottom'}
                visible={showUpdatePopup}
                height={'150'}
                onClose={() => setShowUpdatePopup(false)}
                closable={true}>
                <Row type="flex" justify="end">
                    <Col span={4}>
                        <Button id={'update-button'} type="primary" onClick={() => window.location.reload()}>
                            Update
                        </Button>
                    </Col>
                    <Col span={1}/>
                </Row>
            </Drawer>
        </UpdateContext.Provider>
    );
};


UpdateProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default UpdateProvider;