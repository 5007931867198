import gql from 'graphql-tag';

const GET_BRANDS = gql`
  query Brands {
    general {
      brands
    }
  }
`;

export default GET_BRANDS;