import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import WindowWidthContext from './WindowWidthContext';

const WindowWidthProvider = ({children}) => {
  const [windowExactWidth, setExactWindowWidth] = useState(window.innerWidth);
  const [windowWidth, setWindowWidth] = useState(null);

  const location = window.location.pathname;

  const createTabletContext = (width) => {
           width > 1200 ? setWindowWidth('desktop') : width < 1200 && width > 768 ? setWindowWidth('tablet') : setWindowWidth('mobile')

  }

   useEffect(() => {
    const width = window.innerWidth;
    if (!location.includes('promotions')) {
            width > 1200 ? setWindowWidth('desktop') : setWindowWidth('mobile');
    } else {
        createTabletContext(width)
    }
    window.addEventListener('resize', listenForResize);
    return () => window.removeEventListener('resize', listenForResize);
  }, []);

  const listenForResize = () => {
    const innerWidth = window.innerWidth
    setExactWindowWidth(innerWidth)
    if (!location.includes('promotions')) {
      if (innerWidth > 1200) setWindowWidth('desktop')
      else setWindowWidth('mobile')
    } else {
      createTabletContext(innerWidth)
    }
  };

  return (
    <WindowWidthContext.Provider value={{windowWidth, windowExactWidth}}>
      {children}
    </WindowWidthContext.Provider>
  )
};

WindowWidthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WindowWidthProvider;
