import React, {useState} from 'react';
import PropTypes from 'prop-types';
import MenuContext from "./MenuContext";

const MenuProvider = ({children}) => {
  const [selectedItem, setSelectedItem] = useState('');
  const [openKeys, setOpenKeys] = useState([]);
  const [openKeysLevel, setOpenKeysLevel] = useState([]);

  return (
    <MenuContext.Provider value={{selectedItem, setSelectedItem, openKeys, setOpenKeys, openKeysLevel, setOpenKeysLevel}}>
      {children}
    </MenuContext.Provider>
  )
};

MenuProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MenuProvider;
