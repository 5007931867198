import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Row, InputNumber} from 'antd';

const RangeFilter = ({id, initialValues, updateFilters}) => {
  const [minValue, setMinValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);

  useEffect(() => {
    if (initialValues) {
      setMinValue(initialValues[0]);
      setMaxValue(initialValues[1]);
    }
    else {
      setMinValue(null);
      setMaxValue(null);
    }
  }, [initialValues]);

  const handleChange = (min, max) => {
    setMinValue(min);
    setMaxValue(max);
    if (!min && !max) updateFilters({paramId: id, values: []});
    else updateFilters({paramId: id, values: [min, max]});
  };

  return (
    <Row type='flex' justify='space-around'>
      <InputNumber defaultValue={initialValues && initialValues.min}
                   value={minValue}
                   onChange={e => handleChange(e, maxValue)}
      />
      <span>-</span>
      <InputNumber defaultValue={initialValues && initialValues.max}
                   value={maxValue}
                   onChange={e => handleChange(minValue, e)}
      />
    </Row>
  )
};

RangeFilter.propTypes = {
  id: PropTypes.string.isRequired,
  initialValues: PropTypes.array,
  updateFilters: PropTypes.func.isRequired,
};

export default RangeFilter;