export default {
  'error': 'Błąd',
  'search-product-or-category': 'Wyszukaj po nazwie produktu, kodzie i nazwie kategorii',
  'categories': 'Kategorie',
  'products': 'Produkty',
  'load-more': 'Załaduj więcej',
  'price': 'Cena',
  'filter': 'Filtruj',
  'catalogue-number': 'Numer katalogowy',
  'clear-filters': 'Wyczyść filtry',
  'all-products': 'Wszystkie produkty',
  'only-archive': 'Tylko archiwalne',
  'only-published': 'Tylko aktualne',
  'archive': 'Archiwum',
};