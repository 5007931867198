import React from 'react';

const Error = () => (
  <b>Error</b>
);

Error.defaultProps = {};

Error.propTypes = {};

export default Error;