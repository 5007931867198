import styled from 'styled-components';

const RightContentContainer = styled.div`
	width: auto;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	
	a {
	  display: flex;
	  
	  i {
        font-size: 18px;
        display: inline;
        color: ${({theme}) => theme.color.primaryColor};
      }
	}
	
`;

export default RightContentContainer;