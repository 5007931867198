export default {
  "log-in": "Log in",
  "mail": "Email",
  "password": "Password",
  "pass-err": "Please input your password!",
  "mail-err": "Please input your email!",
  "log-in-err": "Please enter a valid credentials!",
  "forgot": "Forgot password?",
  "smth": "Something get wrong...",
  'advice': 'Always use the login and password to log in as for a ' +
    'professional coded page. If you have forgotten your password, ' +
    'recover it as you would for a coded page.'
}
