import React, {useContext, useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Row, Input, Icon, List, Divider, Radio} from 'antd';
import {Query} from 'react-apollo';
import GET_PRODUCTS from "../../gql/queries/GET_PRODUCTS";
import GET_CATEGORIES from "../../gql/queries/GET_CATEGORIES";
import styled from 'styled-components';
import Cookies from 'universal-cookie';
import {debounce} from 'lodash';
import routes from "../../config/routes";
import {SearchContainer, LightButton} from "../_styledComponents";
import CenteredSpin from "../_styledComponents/CenteredSpin/CenteredSpin";
import WindowWidthContext from "../../context/windowWidth/WindowWidthContext";
import ArchiveContext from "../../context/archive/ArchiveContext";
import MenuContext from "../../context/menu/MenuContext";

  const StyledTitle = styled.p`
    color: ${({theme}) => theme.color.primaryColor};
    font-size: 15px;
    margin: 10px 0;
    
    ${({theme}) => theme.mq.big_desktop`
        padding-left: 10px; 
    `}
  `;

  const StyledErrorSpan = styled.span`
    color: ${({theme}) => theme.color.primaryColor}
    font-size: 50px;
  `;

  const StyledIcon = styled(Icon)`
    font-size: 55px;
    width: 100%;
    color: ${({theme}) => theme.color.primaryColor};
  `;

  const StyledDivider = styled(Divider)`
  margin: 0;
   &.ant-divider-horizontal {
    margin: 0;
  }
`;

  const CheckboxContainer = styled.div`
    margin-top: 10px;
    
    .ant-radio-group  {
      display: flex;
      flex-direction: column;
    }
    
    label {
      margin-left: 10px;
    }
  `;


const SearchComponent = ({t, drawerVisible}) => {
  const cookies = new Cookies();
  const brand = cookies.get('selectedBrand');
  const [searchText, setSearchText] = useState('');
  const [categoriesNumber, setCategoriesNumber] = useState(5);
  const [productsNumber, setProductsNumber] = useState(10);
  const [status, setStatus] = useState('P');
  const {windowWidth} = useContext(WindowWidthContext);
  const {openArchive, setOpenArchive} = useContext(ArchiveContext);
    const {setOpenKeys} = useContext(MenuContext);

  const handleRadioStatusChange = ({target}) => setStatus( target.value);

  const handleChange = (text) => {
    setSearchText(text);
  };

  const debouncedHandle = debounce(handleChange, 400);

  useEffect(() => {
    openArchive && setStatus('A');
  }, [])

  const onProductClick = (product) => {
     if (product.status !== 'A') {
      setOpenArchive(false)
       setOpenKeys([]);
    }
     if (drawerVisible) drawerVisible(false)
    else return null
  }

  const renderProduct = (product) => (
    <List.Item.Meta
      title={
      <NavLink to={`${routes.PRODUCT}/${product.category.id}/${product.id}`}
               onClick={() =>onProductClick(product)}
      >
        <p style={{padding: '5px 0', marginBottom: '0'}}>{product.name}</p>
      </NavLink>
    }
    />
  );

  const renderCategory = (category) => (
    <List.Item.Meta title={
      <NavLink to={`${routes.PRODUCTS_LIST}/${category.id}`}
               onClick={() => drawerVisible ? drawerVisible(false) : null}
      >
        <p style={{padding: '5px 0', marginBottom: '0'}}>
          {category.displayName || category.name} {category.parent&& `(${category.parent.displayName || category.parent.name})`}
        </p>
      </NavLink>
    }
    />
  );

  const incrementCategories = () => setCategoriesNumber(categoriesNumber+5);
  const incrementProducts = () => setProductsNumber(productsNumber+5);

  const renderQuery = (givenQuery, itemsType, renderer, count, increment, additionalVars) => {
    if (searchText) {
      const variables = {brand: brand, first: count, ...additionalVars};
      if (itemsType === 'products') variables.searchText = searchText;
      else variables.searchName = searchText;
      return (
        <Query query={givenQuery}
               variables={variables}
               loading={true}
        >
          {({data, loading, errors}) => {
            if (loading) return <CenteredSpin height={'40vh'}/>;
            else if (!data || errors) {
              return (
                <Row type='flex' justify='center'>
                  <StyledIcon type="frown"/>
                  <StyledErrorSpan>{t('error')}</StyledErrorSpan>
                </Row>
              )
            }
            else {
              const dataRoot = (itemsType === 'products') ? data.general.products : data.general.categories;
              const dataSource = dataRoot.edges;
              const totalCount = dataRoot.totalCount;
              if (dataSource.length) {
                return (
                  <Row>
                    <StyledTitle>{t(itemsType)}</StyledTitle>
                    <StyledDivider/>
                    <List itemLayout='horizontal'
                          dataSource={dataSource}
                          renderItem={item => renderer(item.node)}
                          loadMore={!loading && dataSource.length < totalCount ? (
                              <Row type='flex' justify='center' style={{paddingBottom: '10px'}}>
                                <LightButton themeColor={({theme}) => theme.color.primaryColor}
                                             onClick={() => increment()}>
                                  {t('load-more')}
                                </LightButton>
                              </Row>
                            ) : null
                          }
                    />
                  </Row>
                )
              }
              else return <>
                            <StyledTitle>{t(itemsType)}</StyledTitle>
                             <StyledDivider/>
                            <p style={{paddingLeft: '5px'}}>Brak wyników</p>
                          </>
            }
          }}
        </Query>
      )
    }
  };

  return (
    <SearchContainer>
      <Row>
        {windowWidth === 'mobile' ? <p style={{textAlign: 'center'}}>{t('search-product-or-category')}</p> : null}
        <Input
          allowClear={true}
          addonAfter={<Icon type="search" />}
          style={{width: '100%'}}
          onChange={(e) => debouncedHandle(e.target.value)}
        />
      </Row>
      {searchText && <div className='list-container'>
        <CheckboxContainer>
          <Radio.Group value={status} onChange={handleRadioStatusChange}>
               <Radio value='A, P'>{t('all-products')}</Radio>
              <Radio value='P'>{t('only-published')}</Radio>
              <Radio value='A'>{t('only-archive')}</Radio>
          </Radio.Group>
        </CheckboxContainer>
      {renderQuery(GET_CATEGORIES, 'categories', renderCategory, categoriesNumber, incrementCategories)}
      {renderQuery(GET_PRODUCTS, 'products', renderProduct, productsNumber, incrementProducts, {status: status})}
      </div>}
    </SearchContainer>
  )
};

SearchComponent.propTypes = {
  t: PropTypes.func.isRequired,
  drawerVisible: PropTypes.func.isRequired,
};

const TranslatedComponent = withTranslation('search')(SearchComponent);

export default TranslatedComponent;
