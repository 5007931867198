import React, {useContext} from "react";
import {
    PromoDescription,
    StyledListItem, StyledPrice, StyledPromoPrice
} from "../_styledComponents";
import {Query} from "react-apollo";
import GET_PROMOTED_PRODUCTS from "../../gql/queries/GET_PROMOTED_PRODUCTS";
import CenteredSpin from "../_styledComponents/CenteredSpin/CenteredSpin";
import * as EfficiencyIcons from '../../images/efficiencyIcons';
import AvatarPlaceholder from "../AvatarPlaceholder";
import styled from "styled-components";
import WindowWidthContext from "../../context/windowWidth/WindowWidthContext";
import {Link} from "react-router-dom";

const EfficiencyBar = styled.div`
    position: absolute;
    right: 1%;
    bottom: 25px;
    max-height: 50px;
    svg {
      max-height: 50px;
      max-width: 25px;
      margin-right: 5px;
    }
`;

const StyledHeader = styled.h3`
    margin: 15px auto 0;
    text-align: center;
    font-size: 15px;
    
    ${({theme}) => theme.mq.tablet`
          margin: 25px auto;
    `}
`;

const PromotedProducts = ({brand, t}) => {
    const {windowWidth} = useContext(WindowWidthContext);

    return (
        <div>
            <StyledHeader>{t('bestsellers')}</StyledHeader>
             <Query query={GET_PROMOTED_PRODUCTS} variables={{brand: brand}}>
                 {({data, loading}) => {
                    if (!data) return null;
                    if (loading) return <CenteredSpin/>
                 const items = data.general.productPromoted.edges;
                 return items.map((item,i) => {
                     const product = item.node;
                     const file = product.attachments.edges;
                     const price = product.currentPrice;
                     const previousPrice = product.previousPrice;
                      const previousPriceModified = previousPrice !== null ? new Number(previousPrice.value).toLocaleString('pl-PL') : null;
                      const modifiedPrice = price ? new Number(price.value).toLocaleString('pl-PL') : null;
                      const isCurrentPromo = price !== null ? price.promotion : null;

                     return  (
                         <Link to={`product/discount/${product.id}`} key={i}>
                           <div style={{padding: '0 25px'}}  >
                             <StyledListItem>
                                 <div style={{display: 'flex'}}>
                                     <div style={{position: "relative"}} className={'test'}>
                                           <AvatarPlaceholder file={file} category={null}/>
                                        {windowWidth !== 'desktop' && previousPrice !== null && isCurrentPromo?
                                          <PromoDescription className={'promo promoted'}>
                                                 <span className={'price-bold'}>{modifiedPrice}</span><span>PLN netto</span>
                                             </PromoDescription>

                                         :null }
                                     </div>
                                     <div style={{
                                         display: 'flex',
                                         flexDirection: 'column',
                                         justifyContent: 'space-between',
                                         padding: '0 5px',
                                         width: '100%'
                                     }}>
                                         <h3 style={{color: '#262626', fontSize: '14px',}}>{product.name}</h3>
                                         <p style={{
                                             width: '80%',
                                             lineHeight: '14px',
                                             color: '#77787b',
                                             fontSize: '12px',
                                         }}>
                                             {product.descriptionShort}
                                         </p>
                                         <p style={{
                                             width: '80%',
                                             fontSize: '12px',
                                             lineHeight: '14px',
                                             marginBottom: '0',
                                         }}>
                                             Nr. kat: {product.catalogueNumber}
                                         </p>
                                         <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginRight: '50px', height: '50px'}}>
                                                 {previousPrice !== null && isCurrentPromo ?
                                                <div style={{position: "relative"}}>

                                                        <StyledPromoPrice themeColor={({theme}) => theme[brand].primaryColor}>
                                                            {`${previousPriceModified} PLN netto `}
                                                            <span className={'promo-line'} themeColor={({theme}) => theme[brand].promoLabel}></span>
                                                        </StyledPromoPrice>
                                                    </div>
                                                    : null}
                                                 {previousPrice !== null && windowWidth === 'desktop' && isCurrentPromo?
                                                    <PromoDescription className={'promo promoted'}>
                                                        <span className={'price-bold'}>{modifiedPrice}</span><span>PLN netto</span>
                                                    </PromoDescription> : previousPrice === null && modifiedPrice !== null ?
                                                          <StyledPrice colorSet={({theme}) => theme.color.primaryColor} style={{textAlign: 'left', display: 'inline'}}>
                                                            {`${modifiedPrice} PLN netto`}
                                                          </StyledPrice> : null
                                                 }
                                         </div>
                                     </div>
                                      <EfficiencyBar>
                                      {!!product.efficiencyImages.length && product.efficiencyImages.map((name, index) => {
                                        const component = EfficiencyIcons[name];
                                        if (component !== '') return component.render({key: index})
                                      })}
                                    </EfficiencyBar>
                                 </div>
                             </StyledListItem>
                            </div>
                         </Link>
                     );
                    })
                 }}
              </Query>
        </div>
    );
}

export default PromotedProducts;