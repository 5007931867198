import gql from 'graphql-tag';

const GET_VALIDITY_DATE_BRAND = gql`
query getValidityDateBrand($brand: String){
  general {
    priceValidFrom(brand: $brand) {
      edges {
        node {
          brand
          validFrom
        }
      }
    }
  }
}

`;

export default GET_VALIDITY_DATE_BRAND;