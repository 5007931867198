import gql from 'graphql-tag';

const GET_CATEGORY_NAME = gql`
    query getCategoryName($id: ID, $brand: String){
      general {
        categories(id: $id, brand: $brand) {
          edges {
            node {
              name
              displayName
              parent {
                  id
                  displayName
                  isRoot
                  name
              }
            }
          }
        }
      }
    }
`;

export default GET_CATEGORY_NAME;
