import styled from "styled-components";

const StyledTitle = styled.h2`
  font-size: ${({theme}) => theme.font.m};
  padding: 15px 10px 0;
  margin-bottom: 0;
 
    
	 ${({theme}) => theme.mq.big_desktop`
	  font-size: ${({theme}) => theme.font.l}
	  padding: 30px 0 15px 30px;
	 
  `};
	 
	 
  `;

export default StyledTitle;