import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ArchiveContext from "./ArchiveContext";

const ArchiveProvider = ({children}) => {
  const [openArchive, setOpenArchive] = useState(false);

  return (
    <ArchiveContext.Provider value={{openArchive, setOpenArchive}}>
      {children}
    </ArchiveContext.Provider>
  )
};

ArchiveContext.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ArchiveProvider;