import React, {useContext} from "react";
import {Query} from 'react-apollo';
import GET_PROMOTIONS from "../../gql/queries/GET_PROMOTIONS";
import WindowWidthContext from "../../context/windowWidth/WindowWidthContext";
import PromotionItem from "../PromotionItem";
import CenteredSpin from "../_styledComponents/CenteredSpin/CenteredSpin";
import {Carousel, Icon} from "antd";
import ThemeContext from "../../context/themeContext/themeContext";
import {StrongButton, StyledCarouselContainer, StyledLink} from "../_styledComponents";

const PromotionsList = ({t}) => {
    const {windowWidth} = useContext(WindowWidthContext);
        const {brand} = useContext(ThemeContext);

    const createItem = (data) => {
    return  data.general.promotions.edges.map((promotion, i) => {
          const promoItem = promotion.node;
          const category_id = promoItem.category ? promoItem.category.id : ''
          const product_id = promoItem.product ? promoItem.product.id : ''

           return (
                   <PromotionItem
                      key={promoItem.id}
                      promoItem={promoItem}
                      windowWidth={windowWidth}
                      brand={brand}
                      category={category_id}
                      product={product_id}
                      t={t}
                  />
          );

      });
    }

    const NextSlide = ({ style, className, onClick}) => {
        return (
           <StrongButton
            themeColor={({theme}) => theme[brand].primaryColor}
            className={className}
              style={{
        ...style,
     padding: '10px 10px 10px 20px',
                  width: 'unset',
                  height: 'unset',
                  zIndex: '1',
      }}
            onClick={onClick}
         >
          <img src={'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAARCAYAAAAPFIbmAAAAbklEQVQokYWSMQ5AQBBFRyQKjUqndGcXoKemJdHQUXGVJ/8A9r9ktnqZzP6ZADogA+Kv9IgeyJ0kRqBwkpiB0kliBSoniQOonSQuoHGSeIBW+RBpdtfpVaeUdLuZTve7zeW0uMQnt7vBXUH6niA+S4/3vZ8aDywAAAAASUVORK5CYII='} alt={'next'}/>
         </StrongButton>
        );
    }

    const PreviousSlide = ({className, style, onClick}) => {
        return (
           <StrongButton
             themeColor={({theme}) => theme[brand].primaryColor}
             className={className}
            onClick={onClick}
                    style={{
        ...style,
         padding: '10px 10px 10px 20px',
         width: 'unset',
        height: 'unset',

    zIndex: '1',

      }}
         >
             <img src={'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAARCAYAAAAPFIbmAAAAiUlEQVQokYXSMQqDQBCF4ScRizSp0ll6LM/lCbSPddImpNIuqfQQ6eUPggPLojsPpvtYZmYnA5RIJqnRig7qBLQkQAHc2LIHzsCdIDG4AE+ihOAKDDEIeyqBzx4wVAHTETD0ToHwpdlD1tPXQzbd6CHb08tDtvGHh+zveg/ZFXQryhO3tEiqJf3+rvPlhqebLisAAAAASUVORK5CYII='} alt={'previous'}/>
         </StrongButton>
        );
    }

    const settings = {
        prevArrow: <PreviousSlide/>,
        nextArrow: <NextSlide/>,
        dots: false,
        infinite: true,
        speed: 500,
    }

    return (
        <>
         <StyledLink to='/' style={{padding: '25px 0 0 15px', marginBottom: '0'}}>
            <Icon type="left" style={{fontSize: '14px', marginRight: '5px'}}/>{t('categories')}
          </StyledLink>
            <Query query={GET_PROMOTIONS}
                    variables={{ brand }}
             >
                 {({data, loading}) => {
                     if (!data) return null;
                     if (loading) return <CenteredSpin/>
                     return (
                         <div style={{padding: '0 25px'}}>
                             {windowWidth !== 'desktop' ?
                                 <StyledCarouselContainer>
                                     <Carousel arrows {...settings}>{createItem(data)} </Carousel>
                                 </StyledCarouselContainer>

                                 : createItem(data)}
                       </div>
                     )
                 }}
            </Query>
        </>

    )
}

export  default PromotionsList;