import React from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";

const StyledLink = ({children, ...rest}) => {
  const Styled = styled(Link)`
    font-size: ${({theme}) => theme.font.m};
    color: ${({theme}) => theme.color.primaryColor};
    display: block;
    margin-bottom: 15px;
    
     ${({theme}) => theme.mq.big_desktop`
        display: inline;
    `}
    
    &:hover {
      color: ${({theme}) => theme.color.primaryColorDark};
    }
  `;
  return <Styled {...rest}>{children}</Styled>
};

export default StyledLink;