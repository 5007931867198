import React, {useState} from 'react';
import {withRouter, Redirect} from 'react-router-dom';
import routes from "../../../config/routes";
import LayoutCenter from "../../../containers/LayoutCenter";
import PasswordResetForm from '../../../components/PasswordResetForm';
import FormContainer from "../../../containers/FormContainer";
import {Query} from 'react-apollo';
import {withTranslation} from 'react-i18next';
import PASSWORD_RESET_TOKEN_VALID from "../../../gql/queries/PASSWORD_RESET_TOKEN_VALID";


const PasswordReset = ({match, t}) => {
  const [redirect, setRedirect] = useState(false);
  const url_token = match.params.token;

  if (redirect) return <Redirect to={routes.LOGIN}/>;

  return (
    <Query query={PASSWORD_RESET_TOKEN_VALID}
           variables={{token: url_token}}
    >
      {({loading, data}) => {
        if (loading || !data) return <LayoutCenter><div>{t('validating-token')}</div></LayoutCenter>;
        if (!data.general.valid) return <LayoutCenter><div>{t('token-invalid')}</div></LayoutCenter>;
        return (
          <LayoutCenter>
            <FormContainer>
              <PasswordResetForm redirect={setRedirect}
                                 token={match.params.token}
              />
            </FormContainer>
          </LayoutCenter>
        )
      }}
    </Query>
  )
};

PasswordReset.defaultProps = {};
PasswordReset.propTypes = {};
const TranslatedComponent = withTranslation('passwordReset')(PasswordReset);
export default withRouter(TranslatedComponent);