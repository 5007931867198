export default {
  'data-export': 'Eksport danych',
  'your-templates': 'Twoje szablony',
  'public-templates': 'Szablony ogólnodostępne',
  'edit-template': 'Edytuj szablon',
  'duplicate': 'Duplikuj szablon',
  'delete-template': 'Usuń szablon',
  'card-text': 'Dodany {{formattedDate}} przez {{displayName}}.',
  'confirm-export': 'Czy chcesz eksportować plik z szablonu {{templateName}}?',
  'confirm-export-content': 'Otrzymasz email z wyeksportowanym plikiem. Może to zająć kilka minut.',
  'confirmExportMessageContent': 'Proszę sprawdzić skrzynkę email za kilka minut',
  'export': 'Eksportuj',
  'save': 'Zapisz',
  'cancel': 'Anuluj',
  'confirm-delete': 'Czy na pewno chcesz usunąć szablon {{templateName}}?',
  'delete': 'Usuń',
  'base-error': 'Wystąpił błąd',
  'save-and-export': 'Zapisz i eksportuj',
  'duplicate-button': 'Duplikuj',
  'duplicate-and-export': 'Duplikuj i eksportuj',
  'create-template': 'Tworzenie szablonu do eksportu',
  'edit-export-template': 'Edycja szablonu do eksportu',
  'duplicate-export-template': 'Duplikowanie szablonu do eksportu',
  'export-description-1': 'Każdy plik zawiera nazwę produktu i numer katalogowy.\n' +
    'Jeśli wybierzesz parametr, który nie jest dostępny dla\n' +
    'produktów danej kategorii, to pole to zostanie\n' +
    'oznaczone w pliku jako "Nie dotyczy".',
  'export-description-2': 'Pamiętaj, że parametry produków pojawią się w pliku w wybranej\n' +
     'tutaj kolejności.',
  'template-name': 'Nazwa pliku',
  'brand': 'Marka',
  'template-categories': 'Kategorie produktów',
  'template-parameters': 'Parametry do eksportu',
  'include-attachments': 'Załącz dokumenty',
  'attachment-types': 'Typ dokumentów',
  'delete-success': 'Szablon usunięty',
  'delete-fail': 'Usunięcie szablonu nie powiodło się',
  'screen-too-small': 'Twoje urządzenie jest zbyt małe by otworzyć ten komponent',
  'export-template-success-message': 'Szablon eksportu zapisany',
};