import React from 'react';
import PropTypes from 'prop-types';
import {Icon} from 'antd';
import styled from 'styled-components';
import {Query} from 'react-apollo';
import GET_EXPORT_TEMPLATES from "../../gql/queries/GET_EXPORT_TEMPLATES";
import CenteredSpin from "../_styledComponents/CenteredSpin/CenteredSpin";
import DataExportCard from "../DataExportCard/DataExportCard";

const CenteredSpan = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataExportCards = ({privateTemplates,
                          setModalVisible,
                          setHandledTemplate,
                          setModalAction}) => {
  return (
    <Query query={GET_EXPORT_TEMPLATES}
           variables={{private: privateTemplates}}
    >
      {({loading, data, error}) => {
        if (loading) return <CenteredSpin />;
        if (error || !data) return (
          <CenteredSpan>
            <Icon type='frown'/>
            <span style={{marginLeft: '5px'}}>Error.</span>
          </CenteredSpan>
        );
        const templates = data.me.exportTemplates.edges;
        return templates.map(
          edge => <DataExportCard key={edge.node.id}
                                  template={edge.node}
                                  setModalVisible={setModalVisible}
                                  setHandledTemplate={setHandledTemplate}
                                  setModalAction={setModalAction}
                  />
        )
      }}
    </Query>
  )
};

DataExportCards.propTypes = {
  privateTemplates: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  setHandledTemplate: PropTypes.func.isRequired,
  setModalAction: PropTypes.func.isRequired,
};

export default DataExportCards;