import gql from 'graphql-tag';

const PRODUCT_GENERAL_INFO = gql`
	query ProductGeneralInfo($id: ID) {
  general {
    products(id: $id) {
      edges {
        node {
          mainCategory {
            name
            id
          }
          name
          ean
          catalogueNumber
          id
          status
          descriptionLong
          categoryFullPath
          attachments(type: "P") {
            edges {
              node {
                file (width: 500)
              }
            }
          }
          previousPrice {
            id
            value
            promotion
          }
          currentPrice {
            id
            value
            promotion
          }
          noPrice
          noPriceComment
        }
      }
    }
  }
}
`;

export default PRODUCT_GENERAL_INFO;