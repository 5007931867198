import React from 'react';
import {ApolloProvider} from 'react-apollo';
import { BreakpointProvider } from 'react-socks';
import client from './config/apollo/client';
import Router from './structure/Router';
import './i18n';
import './config/styles/stylesoverride.css';
import MenuProvider from './context/menu/MenuProvider';
import WindowWidthProvider from "./context/windowWidth/WindowWidthProvider";
import FilterProvider from "./context/filter/FilterProvider";
import ReactGA from 'react-ga';
import ArchiveProvider from "./context/archive/ArchiveProvider";


const App = () => {
  ReactGA.initialize(process.env.REACT_APP_GA_TOKEN);
  return (
    <ApolloProvider client={client}>
        <WindowWidthProvider>
            <BreakpointProvider>
                <ArchiveProvider>
                    <MenuProvider>
                        <FilterProvider>
                            <Router/>
                        </FilterProvider>
                    </MenuProvider>
                </ArchiveProvider>
            </BreakpointProvider>
        </WindowWidthProvider>
    </ApolloProvider>
  )
};


export default App;
