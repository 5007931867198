export default {
  'mail': 'Email',
  'mail-err': 'Proszę wprowadzić email!',
  'send-mail-err': 'Podczas wysyłania maila wystąpił błąd',
  'send-mail': 'Wyślij',
  'email-sent': 'Mail został wysłany',
  'basic-err': 'Wystąpił błąd',
  'validating-token': 'Walidacja tokenu...',
  'token-invalid': 'Nieprawidłowy token',
  'pass-err': 'Proszę wprowadzić hasło!',
  'too-short': 'Hasło jest za krótkie',
  'number': 'Brakuje cyfry',
  'lower-upper-case': 'Co najmniej jedna wielka i mała litera',
  'doesnt-match': 'Hasła muszą być takie same',
  'password-changed': 'Hasło zostało zmienione',
  'pass-rules': 'Minimum 8 znaków, jedna cyfra, jedna mała i jedna wielka litera',
  'password': 'Hasło',
  'password-conf': 'Powtórz hasło',
  'reset': 'Zresetuj hasło',
}