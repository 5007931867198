import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import SomethingWentWrong from "../../components/SomethingWentWrong";

class GeneralErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.setState({ hasError: false })
    });
  }
  componentWillUnmount() {
      this.unlisten();
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? <SomethingWentWrong /> : children;
  }
}

export default withRouter(GeneralErrorBoundary);
