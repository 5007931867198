import gql from 'graphql-tag';
const GET_PROMOTED_PRODUCTS = gql`query GetPromotedProducts($brand: String){
      general { 
        productPromoted(brand: $brand) {
            edges{
                node{
                     name
                    id
                    catalogueNumber
                    ean
                    efficiencyImages
                    category {
                      id
                      }
                    noPrice
                    noPriceComment
                    currentPrice {
                        id
                        value
                        promotion
                    }
                     previousPrice{
                        id
                        value
                        promotion
                      }
                    descriptionShort
                    attachments(type: "P", first: 1) {
                        edges {
                          node {
                            file (width: 300)
                          }
                        }   
                    }
                }
            }
          }
        }
      }
`;
export default GET_PROMOTED_PRODUCTS;