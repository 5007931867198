import React from 'react';
import {PromoIconsWrapper} from "../_styledComponents";
import {ReactComponent as  NewsIconV} from "../../images/promoNewsIcons/Ikont-NowoscPromocja-V_SD_2.svg";
import {ReactComponent as NewsIconSD} from "../../images/promoNewsIcons/Ikont-NowoscPromocja-V_SD_4.svg";

const NewsIcons = ({brand}) => {
  return (
    <PromoIconsWrapper>
      {brand === 'V' ? <NewsIconV/> : <NewsIconSD/>}
    </PromoIconsWrapper>
  )
};

export default NewsIcons;