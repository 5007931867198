export default {
  'name': 'Name',
  'last-name': 'Last name',
  'email': 'E-mail',
  'tax-id': 'Tax ID',
  'company': 'Company',
  'phone-number': 'Phone number',
  'save': 'Save',
  'input-name': 'Please, input your first name',
  'input-lastname': 'Please, input your last name',
  'profile-save-success': 'Changes saved successfully',
  'profile-save-error': 'Error'
};