import React from 'react';
import {Icon as IconAnt } from 'antd';
import PropTypes from 'prop-types';

const Icon = ({...props}) => (
  <IconAnt {...props}/>
)

Icon.defaultProps = {
  type: 'user',
  style: {color: 'rgba(0,0,0,.25)'},
};

Icon.propTypes = {
  type: PropTypes.string,
  style: PropTypes.object,
};

export default Icon;