import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledErrorMsg = styled.p`
  min-height: ${({minHeight}) => minHeight};
  font-size: ${({theme}) => theme.font.m};
  color: ${({theme}) => theme.color.antRed};
  text-align: center;
  margin: 1rem 0;
`;

const ErrorMsg = ({children, minHeight, ...rest}) => {
  const parseMinHeight = h => typeof h === 'string' ? h : `${h}px`;

  return (
    <StyledErrorMsg minHeight={parseMinHeight(minHeight)} {...rest}>
      {children}
    </StyledErrorMsg>
  )
};

ErrorMsg.defaultProps = {
  children: '',
  minHeight : '20px'
};

ErrorMsg.propTypes = {
  children: PropTypes.string,
  minHeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default ErrorMsg;