import styled from 'styled-components';

const FlexWrapContainer = styled.div`
  display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: ${({theme}) => `calc(100vh - ${theme.layout.footerHeight} - ${theme.layout.headerHeight})!important}`};
`;

export default FlexWrapContainer;