import styled from "styled-components";

const FlexSpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
  span {
    font-size: 14px;
    font-weight: 300;
    margin-right: 15px;
  }
`;

export default FlexSpaceBetween;