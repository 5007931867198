import React, {useState} from 'react';
import {Button, Icon, Modal, Collapse, Tooltip} from "antd";
import {Query} from "react-apollo";
import GET_FILES from "../../gql/queries/GET_FILES";
import {withTranslation} from "react-i18next";
import {StrongButton} from "../_styledComponents";
import Cookies from 'universal-cookie';
import styled from 'styled-components';

const Filename = styled.p`
  margin-bottom: 0px;
  line-height: 32px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 100px);
`;

const cookies = new Cookies();

const ButtonGroup = Button.Group;

const {Panel} = Collapse;

const FilesList = ({header, id, type, ...props}) => {
    const [modalImage, setModalImage] = useState(null);
    const colorSet = cookies.get('selectedBrand') === 'V' ? 'vaillantColors' : 'saunierColors';

    const handleCancel = e => {
        setModalImage(null)
    };

    return (
        <>
            <Query query={GET_FILES} variables={{id: id, type: type}}>
                {({data, loading}) => {
                    if (!data || !data.general.products.edges[0].node.attachments.edges.length) return null;
                    return (
                        <Panel header={header} {...props}>
                            {data.general.products.edges[0].node.attachments.edges.map((e, index) => {
                            const name = e.node;
                            const lastIndex = name.file.lastIndexOf('/');
                            const nameCutTmp = name.file ? decodeURIComponent(name.file.substring(name.file.indexOf("/", lastIndex) + 1)) : '';
                            const idx = nameCutTmp.indexOf('?');
                            const nameCut = (idx < 0) ? nameCutTmp : nameCutTmp.substring(0, idx);
                            const isImage = ['png','jpg','jpeg','bmp'].indexOf(
                                (/[.]/.exec(nameCut)) ?
                                    /[^.]+$/.exec(nameCut)[0].toLowerCase()
                                    :
                                    undefined
                            ) !==- 1;
                            return <div style={{display: 'flex', justifyContent: 'space-between', margin: '10px'}} key={index}>
                                <Tooltip title={nameCut}
                                         overlayStyle={{maxWidth: '50%'}}
                                >
                                    <Filename><Icon type="link" style={{marginRight: '5px'}}/>{nameCut}</Filename>
                                </Tooltip>
                                <ButtonGroup style={{textAlign: 'right'}}>
                                    <StrongButton
                                      themeColor={({theme}) => theme[colorSet].primaryColor}
                                      style={{width: 'auto', marginRight: '2px'}}>
                                      <a href={`${process.env.REACT_APP_URL}${name.file}`} target='_blank' download>
                                        <Icon type="download"/>
                                      </a>
                                    </StrongButton>
                                    {isImage &&
                                        <StrongButton
                                            themeColor={({theme}) => theme[colorSet].primaryColor}
                                            style={{width: 'auto'}}
                                            onClick={()=>setModalImage(`${process.env.REACT_APP_URL}${name.preview}`)}>
                                            <Icon type="search" />
                                        </StrongButton>
                                    }
                                </ButtonGroup>
                            </div>
                            })}
                        </Panel>
                    )
                }}
            </Query>
            <Modal
                visible={!!modalImage}
                onCancel={handleCancel}
                footer={null}
            >
              <img style={{
              marginTop: '15px',
              maxWidth: '100%',
              minWidth: '100%'}}
              src={modalImage}
              />
            </Modal>
        </>
    )
};

export default withTranslation('productGeneralInfo')(FilesList);
