import React, { useEffect, useState } from "react";
import { removeCategoriesWithoutProducts } from "../../helpers/removeCategoriesWithoutProducts";

import ProductTile from "../ProductTile";
import { useQuery } from "@apollo/react-hooks";
import GET_DEFAULT_CATEGORY_IMAGE from "../../gql/queries/GET_DEFAULT_CATEGORY_IMAGE";

const ConstructTile = ({ categories, newProducts }) => {
  const [categoriesWithProducts, setCategoriesWithProducts] = useState([]);
  const { data: defaultImageData } = useQuery(GET_DEFAULT_CATEGORY_IMAGE);

  useEffect(() => {
    const isMain = categories.some((category) => category.node.isMain);
    if (isMain) {
      setCategoriesWithProducts(
        removeCategoriesWithoutProducts(categories, "main-categories")
      );
    } else {
      setCategoriesWithProducts(
        removeCategoriesWithoutProducts(categories, "subcategories")
      );
    }
  }, [categories]);
  if (newProducts) {
    return (
      <>
        {categories.map((category) => {
          return (
            <ProductTile
              key={category.id}
              category={category}
              newProducts={newProducts}
              imageFallback={
                defaultImageData &&
                defaultImageData.general.categoryDefaultImage
              }
            />
          );
        })}
      </>
    );
  }
  return (
    <>
      {categoriesWithProducts &&
        categoriesWithProducts.map((category) => {
          return (
            <ProductTile
              category={category.node}
              key={category.node.id}
              imageFallback={
                defaultImageData &&
                defaultImageData.general.categoryDefaultImage
              }
            />
          );
        })}
    </>
  );
};

export default ConstructTile;
