import React, {useReducer, useState} from 'react';
import PropTypes from 'prop-types';
import FilterContext from "./FilterContext";


const APPLY_FILTERS = 'APPLY_FILTERS';
const CLEAR_FILTERS = 'CLEAR_FILTERS';

const clearFiltersAction = () => {
  return {type: CLEAR_FILTERS};
};

const applyFiltersAction = filters => {
  return {type: APPLY_FILTERS, filters};
};

const initial = {params: []};

const reducer = (state, action) => {
  switch (action.type) {
    case APPLY_FILTERS:
      state = action.filters;
      return {...state};
    case CLEAR_FILTERS:
      return state = initial;
    default:
      return;
  }
};


const FilterProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initial);
  const [contextCategoryId, setContextCategoryId] = useState(null);

  return (
    <FilterContext.Provider value={{
      state,
      dispatch,
      applyFiltersAction,
      clearFiltersAction,
      contextCategoryId,
      setContextCategoryId
    }}>
      {children}
    </FilterContext.Provider>
  );
};


FilterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FilterProvider;