import styled from 'styled-components';

const LogoWrapper = styled.div`
	width: auto;
	height: ${({theme}) => theme.layout.logoHeight};
	
	svg {
	  	height: ${({theme}) => theme.layout.logoHeight};
	}
`;

export default LogoWrapper;