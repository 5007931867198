import styled from 'styled-components';

const StyledInfoContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	
	  ${({theme}) => theme.mq.big_desktop`
      width: 80%;
      flex-direction: row;
      margin: 0 auto 30px;
  `}
	  
	  .ant-carousel .slick-dots li.slick-active button {
				background: ${({theme}) => theme.color.primaryColor} !important;
	  }
	  
	  .ant-carousel .slick-dots-bottom {
	  	bottom: 15px !important;
	  }

	 	.ant-carousel .slick-dots li button {
	 		background: ${({brand, theme: {color} }) => brand === 'S' ? 'none' : `${color.carousel}` } !important;
	 		border: ${({brand, theme: {color} }) => brand === 'S' ? `2px solid ${color.primaryColor} !important` : 'none'};
			height: 12px !important;
			width: 12px !important;
			border-radius: 50% !important;
			opacity: 1 !important;
			
			&:hover {
				background: ${({theme}) => theme.color.primaryColor} !important;
			}
			
	 	}
	   
  .ant-carousel {
     ${({theme}) => theme.mq.big_desktop`
			 display: inline-block;
			 width: 30% !important;
		`}
   }
`;

export default StyledInfoContainer;