import {createContext, useEffect, useState} from 'react';
import defaultTheme, {saunierTheme, vaillantTheme} from "../../config/styles/theme";
import Cookies from "universal-cookie";
import {ThemeProvider} from "styled-components";
import React from "react";

const ThemeContext = createContext();

const ThemeContextProvider = ({children}) => {
    const [theme, setTheme] = useState(defaultTheme);
    const [brand, setBrand] = useState('');
    const cookies = new Cookies();

     const getTheme = (brand) => {
      switch (brand) {
        case 'V' : return vaillantTheme;
        case 'S' : return saunierTheme;
        default : return defaultTheme;
      }
  };

    const updateTheme = brand => {
        setTheme(getTheme(brand));
        setBrand(brand)
    };

    useEffect(() => {
      if (window.location.pathname !== '/login') {
        if (window && window.history) {
            const brand = cookies.get('selectedBrand');
            const loggedIn = cookies.get('role');
            if (!brand && loggedIn) window.history.pushState(null,'','/brand-select');
            else updateTheme(brand)
        }
      }

    }, []);


  return (
    <ThemeContext.Provider value={{updateTheme, brand}}>
        <ThemeProvider theme={theme} >
          {children}
        </ThemeProvider>
    </ThemeContext.Provider>
  )
};

export {ThemeContextProvider};


export default ThemeContext;