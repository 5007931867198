import React from 'react';
import {Row} from 'antd';
import {withTranslation} from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px 40px;
`;

const Header = styled(Row)`
  font-size: 1.8em;
  margin-bottom: 10px;
`;

const Paragraph = styled.p`
  margin: 15px 0 5px 0;
  font-weight: bold;
`;

const Contents = styled.span`
  white-space: pre-wrap;
  text-align: justify;
`;

const List = styled.ul`
  list-style-type: none;
`;

const TermsOfService = ({t}) => (
  <Container>
    <Header type='flex' justify='center'>{t('header')}</Header>
    <Contents>{t('description')}</Contents>
    <Paragraph>{t('paragraph-definitions')}</Paragraph>
    <Contents>
      <List>
        {t('content-definitions', {returnObjects: true}).map((point, idx) => (
          <li key={`${idx}-definitions`}>{point}</li>
        ))}
      </List>
    </Contents>
    <Paragraph>{t('paragraph-general-provisions')}</Paragraph>
    <Contents>
      <ol>
        {t('content-general-provisions', {returnObjects: true}).map((point, idx) => (
          <li key={`${idx}-general-provisions`}>{point}</li>
        ))}
      </ol>
    </Contents>
    <Paragraph>{t('paragraph-service')}</Paragraph>
    <Contents>
      <ol>
        {t('content-service', {returnObjects: true}).map((point, idx) => (
          <li key={`${idx}-service`}>{point}</li>
        ))}
      </ol>
    </Contents>
    <Paragraph>{t('paragraph-bugs')}</Paragraph>
    <Contents>
      <ol>
        {t('content-bugs', {returnObjects: true}).map((point, idx) => (
          <li key={`${idx}-bugs`}>{point}</li>
        ))}
      </ol>
    </Contents>
    <Paragraph>{t('paragraph-data-protection')}</Paragraph>
    <Contents>
      <ol>
        {t('content-data-protection', {returnObjects: true}).map((point, idx) => (
          <li key={`${idx}-data-protection`}>{point}</li>
        ))}
      </ol>
    </Contents>
    <Paragraph>{t('paragraph-final-provisions')}</Paragraph>
    <Contents>
      <ol>
        {t('content-final-provisions', {returnObjects: true}).map((point, idx) => (
          <li key={`${idx}-final-provisions`}>{point}</li>
        ))}
      </ol>
    </Contents>
  </Container>
);

export default withTranslation('termsOfService')(TermsOfService);