import gql from 'graphql-tag';

const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserMutationInput!) {
    updateUserMutation(input: $input) {
      id
      username
      role
      firstName
      lastName
      email
      taxId
      companyName
      phoneNumber
      errors {
        field
        messages
      }
    }
  }
`;

export default UPDATE_USER;