import React from 'react';
import BrandSelector from "../../../components/BrandSelector";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const BrandSelect = () => {
    if (cookies.get('selectedBrand')) cookies.remove('selectedBrand', {path: '/'});
    return <BrandSelector/>;
};

BrandSelect.propTypes = {};

export default BrandSelect;