import styled from 'styled-components';

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr; 
	max-width: 1360px;
	width: 100%;
	margin: 0 auto;
`;

export default HeaderContainer;