import styled from "styled-components";

const StyledTileGridLayout = styled.div`
  margin: 0 auto;
  justify-content: center;
  display: grid;
  grid-gap: .75rem;
  max-width: 1200px;
  grid-template-columns: repeat(2, 1fr);

  ${({theme}) => theme.mq.smallMobile`
    grid-gap: .25rem;
    grid-template-columns: repeat(2, 1fr);
  `}

  ${({theme}) => theme.mq.phone`
    grid-gap: .25rem;
    grid-template-columns: repeat(3, 1fr);
  `}

  ${({theme}) => theme.mq.tablet`
    grid-gap: .5rem;
    grid-template-columns: repeat(4, 1fr);
  `}

  ${({theme}) => theme.mq.desktop`
    grid-template-columns: repeat(5, 1fr);
  `}
`;

export default StyledTileGridLayout;
