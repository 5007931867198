import gql from 'graphql-tag';

const GET_ATTACHMENT_TYPES = gql`
  query {
    general {
      attachmentTypeValues 
    }
  }
`;

export default GET_ATTACHMENT_TYPES;