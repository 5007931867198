import React, {useEffect, useState} from 'react';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {Form, Input, Button, Tooltip, message} from 'antd';
import {withApollo} from 'react-apollo';
import {ErrorMsg, Icon} from "../_styledComponents";
import PASSWORD_RESET from '../../gql/mutations/PASSWORD_RESET';

const hasErrors = fieldsError => {
  return Object.keys(fieldsError).some(field => fieldsError[ field ]);
};

message.config({maxCount: 1});

const PasswordResetForm = ({token, redirect, form, client, t}) => {
  const {getFieldDecorator, getFieldValue, getFieldsError, isFieldTouched, validateFields} = form;
  const [generalError, setGeneralErrorMsg] = useState('');
  const [passwordErrorMsg, setPasswordErrorMsg] = useState('');
  const [passwordConfErrorMsg, setPasswordConfErrorMsg] = useState('');

  useEffect(() => {
    validateFields();
  }, []);

  const handleError = () => {
    message.error(t('basic-err'), 10);
  };

  const resetError = () => {
    setGeneralErrorMsg('');
  };

  const validatePassword = (rule, value, callback) => {
    if (isFieldTouched('password')) {
      if (!value) {
        setPasswordErrorMsg(t('pass-err'));
        callback('error');
      } else if (value.length < 8) {
        setPasswordErrorMsg(t('too-short'));
        callback('error');
      } else if (!/\d/.test(value)) {
        setPasswordErrorMsg(t('number'));
        callback('error');
      } else if (!/(?=.*[A-Z])(?=.*[a-z])/.test(value)) {
        setPasswordErrorMsg(t('lower-upper-case'));
        callback('error');
      } else {
        setPasswordErrorMsg('');
        callback();
      }
    }
    callback()
  };

  const validatePasswordConf = (rule, value, callback) => {
    const password = getFieldValue('password');
    if (isFieldTouched('passwordConf')) {
      if (!value) {
        setPasswordConfErrorMsg(t('pass-err'));
      } else if (password !== value) {
        setPasswordConfErrorMsg(t('doesnt-match'));
        callback('error')
      } else {
        setPasswordConfErrorMsg('');
        callback();
      }
    }
    callback();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((err, value) => {
      if (!err) {
        client.mutate({
          mutation: PASSWORD_RESET,
          variables: {
            input: {
              token: token,
              password: value.password,
              passwordConfirm: value.passwordConf
            }
          }
        }).then(res => {
          if (res.data.passwordResetMutation.ok) {
            message.success(t('password-changed'), 10);
            redirect(true);
          }
        }).catch((err) => setGeneralErrorMsg(t('basic-err')));
      } else {
        handleError();
      }
    });
  };

  return (
    <Form onSubmit={(e) => handleSubmit(e)}
          style={{height: '150px'}}
    >
      <Form.Item validateStatus={!!passwordErrorMsg ? 'error' : ''}
                 help={passwordErrorMsg || ''}
      >
        <Tooltip trigger={['focus']}
                 title={t('pass-rules')}
                 placement='topLeft'
        >
          {getFieldDecorator('password', {
            rules: [{required: true}, {validator: validatePassword}],
          })(
            <Input.Password onChange={() => resetError()}
                            prefix={<Icon type='lock'/>}
                            placeholder={t('password')}
            />
          )}
        </Tooltip>
      </Form.Item>
      <Form.Item validateStatus={!!passwordConfErrorMsg ? 'error' : ''}
                 help={passwordConfErrorMsg || ''}
      >
        {getFieldDecorator('passwordConf', {
          rules: [{required: true}, {validator: validatePasswordConf}],
        })(
          <Input.Password onChange={() => resetError()}
                          prefix={<Icon type='lock'/>}
                          placeholder={t('password-conf')}
          />
        )}
      </Form.Item>
      <Form.Item>
        <Button type='primary'
                htmlType='submit'
                style={{width: '100%'}}
                disabled={hasErrors(getFieldsError())}
        >
            {t('reset')}
        </Button>
      </Form.Item>
      <ErrorMsg minHeight={25}>
        {generalError}
      </ErrorMsg>
    </Form>
  )
};

PasswordResetForm.propTypes = {
  form: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const AntPasswordResetForm = Form.create()(PasswordResetForm);
const TranslatedComponent = withTranslation('passwordReset')(AntPasswordResetForm);
export default withApollo(TranslatedComponent);
