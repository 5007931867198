export default {
  'value': 'Value',
  'unit': 'Unit',
  'equipment-obligatory': 'Obligatory equipment',
  'equipment-additional' : 'Additional equipment',
  'equipment-delivered' : 'Delivered equipment',
  'product-code': 'Product code',
  'no-price': 'No price',
  'yes' : 'Yes',
  'no' : 'No',
  'technical-parameters': 'Technical parameters',
  'documents': 'Documents',
  'logistic-parameters': 'Logistic parameters',
}