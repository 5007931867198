import React, {useContext, useState} from 'react';
import {NavLink} from "react-router-dom";
import routes from "../../config/routes";
import {Icon, Select} from "antd";
import Cookies from 'universal-cookie';
import {RightContentContainer} from "../_styledComponents";
import ThemeContext from "../../context/themeContext/themeContext";
import {withRouter} from 'react-router-dom';


const cookies = new Cookies();
const { Option } = Select;


const DesktopHeaderRight = ({history}) => {
  const [stateBrand, setStateBrand] = useState(cookies.get('selectedBrand'));
  const  {updateTheme}  = useContext(ThemeContext);
   const logout = () => {
    cookies.remove('jwttoken', {path: '/'});
    cookies.remove('role', {path: '/'});
    cookies.remove('selectedBrand', {path: '/'});
  };

   const handleChange = (value) => {
     setStateBrand(value);
     cookies.set('selectedBrand', value, {path: '/'});
     updateTheme(value);
     if (history.location.pathname !== '/') {
       history.push('/')
     }
   };

  return (
        <RightContentContainer>
            <Select onChange={handleChange}
                    style={{width: '200px'}}
                    defaultValue={stateBrand}
            >
              <Option value="V">Vaillant</Option>
              <Option value="S">Saunier Duval</Option>
            </Select>
            <NavLink to={routes.PROFILE}>
              <Icon type='smile' title='Mój profil'/>
            </NavLink>
            <NavLink  to={routes.DASHBOARD}>
              <Icon type='logout' onClick={logout} title='Wyloguj'/>
            </NavLink>
        </RightContentContainer>
  )
};

export default withRouter(DesktopHeaderRight);