import gql from 'graphql-tag';

const GET_FILES = gql`
	  query documentsForProduct($id: ID, $type: String) {
      general {
        products(id: $id) {
          edges {
            node {
              id
              attachments(type: $type) {
                edges {
                  node {
                    preview: file(width: 500)
                    file
                    id
                    type
                  }
                }
              }
            }
          }
        }
      }
    }

`;

export default GET_FILES;