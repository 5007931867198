import gql from 'graphql-tag';

const SEND_PASSWORD_RESET_MAIL = gql`
  mutation SendPasswordResetMail($input: SendPasswordResetMailMutationInput!) {
    sendPasswordResetMailMutation(input: $input){
      ok
      errors {
        field
        messages
      }
    }
  }
`;

export default SEND_PASSWORD_RESET_MAIL;