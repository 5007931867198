export default {
  'name': 'Imię',
  'last-name': 'Nazwisko',
  'email': 'E-mail',
  'tax-id': 'NIP',
  'company': 'Firma',
  'phone-number': 'Numer telefonu',
  'save': 'Zapisz',
  'input-name': 'Proszę wprowadzić imię',
  'input-lastname': 'Proszę wprowadzić nazwisko',
  'profile-save-success': 'Zmiany zostały zapisane',
  'profile-save-error': 'Wystąpił błąd'
};