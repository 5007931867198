import gql from 'graphql-tag';

const GET_FILTERS = gql`
  query getMainParams($id: ID) {
    general {
      categoryParameterFilters(category: $id) {
        edges {
          node {
            id
            parameter {
              id
              name
              type
              choices
              unit
              models
            }
          }
        }
      }
    }
  }
`;

export default GET_FILTERS;