import React from 'react';
import {PromoIconsWrapper} from "../_styledComponents";
import {ReactComponent as PromoIconV} from "../../images/promoNewsIcons/Ikont-NowoscPromocja-V_SD_1.svg";
import {ReactComponent as PromoIconSD} from "../../images/promoNewsIcons/Ikont-NowoscPromocja-V_SD_3.svg";

const PromoIcons = ({brand}) => {
  return (
    <PromoIconsWrapper>
      {brand === 'V' ? <PromoIconV/> : <PromoIconSD/>}
    </PromoIconsWrapper>
  )
};

export default PromoIcons;