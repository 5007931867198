export default {
  'header': 'Regulamin Aplikacji "Cennik"',
  'description': 'Niniejszy Regulamin określa ogólne warunki, zasady ' +
    'oraz sposób świadczenia usług drogą elektroniczną przez ' +
    'Usługodawcę za pośrednictwem aplikacji  (zwanej dalej: ' +
    '„Aplikacją”).',
  'paragraph-definitions': '§ 1 Definicje',
  'content-definitions': [
    'Aplikacja  - oznacza usługę dostarczenia informacji o ofercie i ' +
    'produktach pod adresem URL',
    'Regulamin - oznacza niniejszy regulamin Aplikacji.',
    'Usługodawca - oznacza Vaillant Saunier Duval Sp. z o.o., ul. 1 ' +
    'Sierpnia 6A, budynek  C, 02-134 Warszawa',
    'Użytkownik – osoba prawna, która uzyskała dostęp do Aplikacji na ' +
    'bazie stosownej umowy handlowej lub autoryzacyjnej.'
  ],
  'paragraph-general-provisions': '§ 2 Postanowienia ogólne i ' +
    'korzystanie z Aplikacji',
  'content-general-provisions': [
    'Aplikacja jest udostępniana przez Usługodawcę za pośrednictwem ' +
    'sieci Internet, jako zasób systemu teleinformatycznego oraz ' +
    'informatycznego.',
    'Do minimalnych wymogów technicznych wystarczy posiadanie na ' +
    'używanych urządzeniach aktualizowanej przeglądarki z dostępem ' +
    'do Internetu, która wspiera technologię Progressive Web ' +
    'Applications.',
    'Usługodawca oświadcza, iż publiczny charakter sieci Internet ' +
    'i korzystanie z usług świadczonych drogą elektroniczną wiązać ' +
    'może się z zagrożeniem pozyskania i modyfikowania danych ' +
    'Użytkowników przez osoby nieuprawnione, dlatego Użytkownicy ' +
    'powinni stosować właściwe środki techniczne, które zminimalizują ' +
    'wskazane wyżej zagrożenia. W szczególności stosować programy ' +
    'antywirusowe i chroniące tożsamość korzystających z sieci Internet.',
  ],
  'paragraph-service': '§ 3 Usługa',
  'content-service': [
    'Usługodawca świadczy drogą elektroniczną na rzecz Użytkowników ' +
    'usługi nieodpłatne. Usługi są świadczone 24 godziny na dobę, 7 dni ' +
    'w tygodniu, z wyłączeniem okresów niezbędnych do wykonania prac ' +
    'konserwacyjnych wymagających krótkotrwałego wyłączenia działania ' +
    'aplikacji.',
    'Aplikacja jest usługą dedykowaną dla działalności B2B. Nie jest ' +
    'przeznaczona dla odbiorcy końcowego. Jest dostępna tylko z kodowanych ' +
    'stron internetowych Usługodawcy dla osób zarejestrowanych.',
    'Usługodawca świadczy na rzecz Użytkowników, drogą elektroniczną ' +
    'następujące usługi nieodpłatne:\n' +
    '        a. Informowaniu o aktualnym portfolio Usługodawcy,\n' +
    '        b. Udostępnienie danych produktowych\n' +
    '        c. Udostępnienie dokumentacji technicznej i innej wymaganej z powodów formalnych\n' +
    '        d. Materiałów marketingowych.\n',
    'Zakres usług jak i treści dostarczanych za pomocą Aplikacji może ' +
    'być modyfikowany przez Usługodawcę.',
    'Materiały zdjęciowe zawarte w Aplikacji mają charakter poglądowy i ' +
    'mogą wykazywać różnice w stosunku to aktualnego stanu produktu co ' +
    'do np. wyglądu, kompletacji. Bez zgody Usługodawcy materiały ' +
    'zdjęciowe nie mogą być wykorzystane do własnych publikacji ' +
    'Użytkownika. Zamieszczone w Aplikacji instrukcje montażu i obsługi ' +
    'są podstawowym źródłem danych przy określeniu cech i funkcjonalności ' +
    'produktów.',
    'Aplikacja nie jest programem doboru, aczkolwiek zawiera informacje, ' +
    'które pomagają w kompletacji produktów.',
    'Warunki podane w Aplikacji nie stanowią oferty w rozumieniu kodeksu ' +
    'cywilnego',
    'Podane ceny nie zawierają ustawowego podatku VAT.',
    'Ceny podane w Aplikacji nie są przeznaczone dla odbiorcy końcowego ' +
    'i służą jedynie za podstawę do obliczeń.',
  ],
  'paragraph-bugs': '§ 4 Zgłaszanie usterek w działaniu Aplikacji',
  'content-bugs': [
    'Zgłaszanie usterek technicznych należy realizować na adres mailowy ' +
    'bok@materna.com.pl.',
  ],
  'paragraph-data-protection': '§ 7 Tajemnica i ochrona danych',
  'content-data-protection': [
    'Strony umowy zobowiązują się bezterminowo zachować w tajemnicy ' +
    'wiedzę, wszystkie informacje poufne i tajemnice przedsiębiorstwa ' +
    'drugiej strony, uzyskane w ramach współpracy, a ponadto wykorzystać ' +
    'tę wiedzę, informacje i tajemnice włącznie w celu wykonania umowy. ' +
    'Nieodpłatne oprogramowanie także stanowi tajemnicę przedsiębiorstwa ' +
    'Vaillant.',
    'Zasady ochrony Danych Osobowych zamieszczone są w Polityce prywatności.',
  ],
  'paragraph-final-provisions': '§ 8 Postanowienia końcowe i zmiana Regulaminu',
  'content-final-provisions': [
    'Regulamin wchodzi w życie z dniem xx.12.2019 r.',
    'Usługodawca zastrzega sobie prawo zmiany niniejszego Regulaminu.',
    'Treść niniejszego Regulaminu może zostać utrwalona poprzez ' +
    'wydrukowanie, zapisanie na nośniku lub pobranie w każdej chwili ze ' +
    'strony internetowej Usługodawcy.',
    'Właściwym sądem jest – według wyboru Usługodawcy – sąd właściwy ze ' +
    'względu na jego siedzibę lub ze względu na siedzibę Użytkownika.',
  ],
};