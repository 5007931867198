import gql from 'graphql-tag';

const GET_PROMOTIONS = gql`query getPromotions($brand: String) {
    general {
        promotions(brand: $brand) {
            edges {
                node {
                    id,
                    brand,
                    title,
                    description,
                    expDateFrom,
                    expDateTo,
                    product {
                        id,
                        name,
                        catalogueNumber,
                    }
                    category {
                        id,
                        name,
                    },
                    imageTablet,
                    imageMobile,
                    imageDesktop,
                    fileProductData,
                    fileProductPrice,
                }
            }
        }
    }
}
`;

export default GET_PROMOTIONS;