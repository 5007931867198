import React, {useContext, useEffect, useState} from 'react';
import {Query, withApollo} from 'react-apollo';
import {useQuery} from "@apollo/react-hooks";
import styled from 'styled-components';
import CategoryTile from "../../../components/CategoryTile";
import GET_MAIN_CATEGORIES from "../../../gql/queries/GET_MAIN_CATEGORIES";
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";
import StyledTile from "../../../components/_styledComponents/StyledTile";
import MenuContext from "../../../context/menu/MenuContext";
import CenteredSpin from "../../../components/_styledComponents/CenteredSpin/CenteredSpin";
import PromoIcons from "../../../components/PromoIcons/PromoIcons";
import NewsIcons from "../../../components/NewsIcons/NewsIcons";
import ThemeContext from "../../../context/themeContext/themeContext";
import GET_NEW_PRODUCTS_CATEGORIES from "../../../gql/queries/GET_NEW_PRODUCTS_CATEGORIES";
import {withRouter} from 'react-router';
import {StyledLink} from "../../../components/_styledComponents";
import {Icon, message} from "antd";
import ArchiveContext from "../../../context/archive/ArchiveContext";

const StyledContainer = styled.div`
  margin: 0 auto;
  max-width: ${({theme}) => theme.layout.containerWidth};
  display: grid;
  grid-column-gap: .25rem;
  grid-row-gap: .25rem;
  grid-template-columns: repeat(auto-fit, minmax(9.75rem, .5fr));
  
   ${({theme}) => theme.mq.smallMobile`
     grid-template-columns: repeat(auto-fit, minmax(9.75rem, .5fr));
  `}
  
  ${({theme}) => theme.mq.phone`
     grid-template-columns: repeat(auto-fit, minmax(9.75rem, .33fr));
  `}
  
  ${({theme}) => theme.mq.tablet`
     grid-template-columns: repeat(auto-fit, minmax(9.75rem, .25fr));
  `}
  
  ${({theme}) => theme.mq.desktop`
    grid-column-gap: .5rem;
    grid-row-gap: .5rem;
     grid-template-columns: repeat(auto-fit, minmax(9.75rem, 185px));
  `}
  
  ${({theme}) => theme.mq.big_desktop`
     margin: 30px;
     max-width: 1200px;
      grid-template-columns: repeat(auto-fit, minmax(9.75rem, 185px));
     
  `}
`;

const Dashboard = ({match, client, t}) => {
  const {setSelectedItem, setOpenKeys} = useContext(MenuContext);
  const [height, setHeight] = useState(null);
  const [filteredCategories, setFilteredCategories] = useState([])
    const [loading, setLoading] = useState(false);
  const [isNewView, setIsNewView] = useState(false)
  const {brand} = useContext(ThemeContext);
  const {setOpenArchive} = useContext(ArchiveContext);

  useEffect(() => {
    setSelectedItem('categories');
    setOpenKeys([]);
    match.path.includes('/new') ? setIsNewView(true) : setIsNewView(false)
    creteCategoriesForNew();
    setOpenArchive(false)
  }, []);

  const setTileHeight = (size) => {
    setHeight(size)
  };

  const creteCategoriesForNew = () => {
       if (brand !== '') {
          setLoading(true)
          client.query({
              query: GET_NEW_PRODUCTS_CATEGORIES,
              variables: {
                  brand: brand
              },
              fetchPolicy: "no-cache",
          }).then(({data}) => {
             const categories = data.general.products.edges.map(product => {
                 return product.node.mainCategory
             });
             const filteredCategories = categories.filter((item, i, array) => {
                 return  (
                    i === array.findIndex((t) => (t.id === item.id ))
                 )
             });
              setFilteredCategories(filteredCategories)
              setLoading(false);
          }).catch(err => {
             message.error(t('error'))
          })
      }
  }

  const NewsTile = () => {
      return (

    <Link to={`/new`}>
      <StyledTile tileHeight={height === null ? '170' : height} themeColor={({theme}) => theme.color.tileColor}>
        <NewsIcons brand={brand}/>
      </StyledTile>
    </Link>

  );
    }

  const DiscountTile = () => {
      return (
      <Link to={`/promotions`}>
      <StyledTile tileHeight={height === null ? '170' : height} themeColor={({theme}) => theme.color.tileColor}>
        <PromoIcons brand={brand}/>
      </StyledTile>
    </Link>
      )
  }

  return (
      <>
      {isNewView ?
          <StyledLink to='/' style={{padding: '25px 0 0 15px', marginBottom: '0', display: 'block'}}>
            <Icon type="left" style={{fontSize: '14px', marginRight: '5px'}}/>{t('categories')}
          </StyledLink> : null}
    <StyledContainer className={isNewView ? "newView" : ''}>
        {
             isNewView ?  filteredCategories.map((category) => {
                              return <CategoryTile key={category.id}
                                           stateHeight={setTileHeight}
                                           newProducts
                                           height={height}
                                           id={category.id}
                                           name={category.name}
                                           logo={category.logoPath}/>
                          }
                     )

              :
                 <Query query={GET_MAIN_CATEGORIES}
                     variables={{
                         brand: brand
                     }}
                     fetchPolicy='cache-first'

              >
                {({data, loading}) => {

                  if (!data) return null;
                  if (loading) return <CenteredSpin width='100vw' height='60vh'/>;

                  return (

                    <>
                       <NewsTile/>
                       <DiscountTile/>
                      {data && data.general.categories.edges.map(({node}) => {
                          return <CategoryTile key={node.id}
                                               stateHeight={setTileHeight}
                                               height={height}
                                               id={node.id}
                                               name={node.name}
                                               logo={node.logoPath}/>
                      })}
                    </>
                  )
                }}
              </Query>
        }

    </StyledContainer>
          </>
  );
};

Dashboard.propTypes = {
    t: PropTypes.func.isRequired,
};

const DashboardWithRouter = withRouter(Dashboard)
const TranslatedComponent = withTranslation('dashboard')(DashboardWithRouter);
export default withApollo(TranslatedComponent);
