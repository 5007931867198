import gql from 'graphql-tag';

const PASSWORD_RESET = gql`
  mutation PasswordReset($input: PasswordResetMutationInput!) {
    passwordResetMutation(input: $input) {
      ok
      errors {
        field
        messages
      }
    }
  }
`;

export default PASSWORD_RESET;