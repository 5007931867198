export default {
  'vaillant-offer': 'Oferta Vaillant',
  'saunier-offer': 'Oferta Saunier Duval',
  'new-products': 'Nowe produkty',
  'discount-products': 'Produkty w promocji',
  'invalid-category': 'Taka kategoria nie istnieje. Spróbuj użyć innej marki.',
  'catalogue-number': 'Nr kat.',
  'no-description': 'Brak opisu',
  'new' : 'Nowości',
  'dateFrom': 'Cennik ważny od: ',
}