import React from 'react';
import PropTypes from 'prop-types';
import SearchComponent from '../../../../components/SearchComponent';

const Search = ({drawerVisible}) => {
  return (
    <SearchComponent drawerVisible={drawerVisible} />
  );
};

Search.propTypes = {
  drawerVisible: PropTypes.func.isRequired,
};

export default Search;