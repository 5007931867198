import styled from 'styled-components';

const PromoIconsWrapper = styled.div`
  padding: 0 15px;
  z-index: 1;
  position: relative;
	svg {
	  max-height: 100px;
	}
`;

export default PromoIconsWrapper;
