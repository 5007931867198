import styled from "styled-components";

const StyledCarouselContainer = styled.div`
    position: relative;
     width: 100%;
     
     .slick-slide {
        display: flex !important;
        justify-content: center;
        border-bottom: 2px solid #e8e8e8;
        
        & > div {
            width: 100%;
        }
     }
`;

export default  StyledCarouselContainer;